@import "~scss/vars";

.payment-docs-list-filter {
	display: flex;

	&__button {
		margin-right: 20px;

		&--last {
			margin-right: 0;
		}

		&--active {
			border: 1px solid $color-input-btn-focus;
		}
	}
}
