@import "~scss/vars";

.select {
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	z-index: 1;
	border: 1px solid $border-color;
	border-radius: $border-radius;

	&--open {
		z-index: 2;
	}

	&--disabled {
		background-color: transparent;
	}

	&__title {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		color: $color-main;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;
		line-height: $line-height-default;
		cursor: pointer;

		&Placeholder {
			margin-right: 15px;
		}

		&Text {
			display: inline-flex;
			max-width: calc(100% - 43px); // 43px - отступ слева + отступ иконки + ширина иконки + отступ от иконки 5px
			z-index: -1;
			overflow: hidden;
			padding-left: 15px;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}



	&__icon {
		position: absolute;
		top: 0;
		right: 15px;
		bottom: 0;
		z-index: -1;
		margin: auto;
		transition: all 0.2s linear;
		fill: $color-second;

		&--open {
			transform: rotate(180deg);
		}
	}

	&__options {
		position: absolute;
		top: calc(100% + 5px);
		width: 100%;
		border: 1px solid $border-color;
		border-radius: $border-radius;
	}

	&__option {
		display: flex;
		align-items: center;
		min-height: 34px;
		padding-left: 15px;
		background-color: $background-color-block;
		color: $color-main;
		font-size: $font-size-text-default;
		line-height: $line-height-large;
		cursor: pointer;

		&:first-child {
			border-top-left-radius: $border-radius;
			border-top-right-radius: $border-radius;
		}

		&:last-child {
			border-bottom-right-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
		}

		&--selected {
			background-color: #ffffff;
			color: $color-theme-main;
		}
	}
}