@import "~scss/vars";

.modal-add-to-favorite {
	width: 320px;
	padding: 0;

	&__top {
		position: relative;
		padding: 21px 0;
		border-bottom: 1px solid #dde0e7;
	}

	&__title {
		padding-left: 24px;
		color: #0d1011;
		font-family: $font-family-bold;
		font-size: 16px;
		line-height: 140%;
	}

	&__icon {

		&--close {
			position: absolute;
			top: 0;
			right: 24px;
			bottom: 0;
			margin: auto;
			cursor: pointer;
		}
	}

	&__items {
		margin-top: 24px;

		&--notEmpty {
			padding-bottom: 30px;
		}
	}

	&__item {
		display: flex;
		justify-content: space-between;
		padding: 0 32px;

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		&--proccess {
			opacity: 0.5;
			pointer-events: none;
		}

		&Count {
			height: 20px;
			margin-left: 14px;
			padding: 0 6px;
			background: #ff7800;
			border-radius: 4px;
			color: #ffffff;
			font-size: 14px;
			font-weight: 600;
		}
	}

	&__action {
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;
		padding: 0 32px;
		padding-bottom: 16px;
		border-bottom: 1px solid #dde0e7;

		&--openAddNewFolder {
			margin-bottom: 0;
			border-bottom: none;
		}
	}

	&__button {
		display: flex;
		justify-content: center;
		width: calc(50% - 15px);
		height: 42px;
		margin: 0;
		border: 1px solid #dde0e7;
		border-radius: 8px;
		color: #0058b0;
		font-size: 14px;
		font-weight: 600;
		line-height: 130%;

		&--change {
			margin-bottom: 8px;
			border: 1px solid #0058b0;
			background-color: #0058b0;
			color: #ffffff;

			&:hover {
				border: 1px solid #004080;
				background-color: #004080;
			}
		}
		
		&--cancel {

			&:hover {
				border: 1px solid #e2e5eb;
				background-color: #e2e5eb;
				color: #0058b0;
			}
		}

		&--disable {
			opacity: 0.5;
			pointer-events: none;
			cursor: no-drop;
		}
	}

	&__checkbox {

		& > label {
			display: block;
			max-width: 170px;
			word-wrap: break-word;
		}
	}
}



