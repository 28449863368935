@import "~scss/vars";

.agreements-list {
	
	&__title {
		margin-bottom: 17px;
		padding: 0px 20px;
		color: #000000;
		font-size: $font-size-text-large;
		line-height: 22px;
	}

	&__radio {
		padding: 0px 20px;

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		&--zIndexNegative {

			label {
				z-index: -1;
			}
		}

		label {
			font-family: $font-family-default;
			font-size: $font-size-text-large;

			&:before {
				width: 10px;
				height: 10px;
			}

			&:after {
				width: 8px;
				height: 8px;
			}
		}
	}
}

