@import "~scss/vars";

.input-calendar {
	position: relative;

	&__control {
		cursor: pointer;

		input {
			cursor: pointer;
		}

		&Icon {
			position: absolute;
			top: 0;
			
			bottom: 0;
			margin: auto;

			&--calendar {
				right: 10px;
			}

			&--delete {
				right: 45px;
				width: 15px;
				height: 15px;
				cursor: pointer;
				fill: #9b9c9d;
			}
		}
	}

	&__сalendar {
		position: absolute;
		top: calc(100% + 5px);
		z-index: 1;
	}
}