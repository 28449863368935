@import "~scss/vars";

.counterparty-form {
	display: flex;
	flex-direction: column;
	width: 400px;
	margin-top: 120px;
	margin-left: 140px;
	$self: &;

		
	&__question {
		display: flex;
		margin-bottom: 50px;
		color: rgba(15, 14, 14, 0.6);
		font-size: $font-size-text-large;
		line-height: $line-height-default;

		&Link {
			margin-left: 6px;
			color: $color-link;
			cursor: pointer;
		}
	}

	&__select {
		height: 50px;

		.select__option {
			height: 50px;
		}

		&--agreement {
			margin-top: 30px;
		}
	}

	&__input {
		margin-top: 30px;

		input {
			padding-left: 15px;
			color: $color-main;
			font-family: $font-family-semibold;
			font-size: $font-size-text-large;
			line-height: $line-height-default;
		}
	}

	&__notAgreement {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
		color: $color-error;
	}

	&__auth {
		display: flex;
		margin-top: 50px;

		&Logout {
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 105px;
			height: 56px;
			margin-right: 20px;
			border: 1px solid #cbd3dc;
			border-radius: 3px;
			cursor: pointer;
			transition: all 0.2s linear;
			$self: &;

			&:hover {
				border-color: $border-focus-color;
				color: $border-focus-color;
				#{ $self }Icon {
					fill: $border-focus-color;
				}
			}

			&Icon {
				margin-top: 2px;
				margin-right: 5px;
				transform: rotate(
					90deg
				) scale(0.85);
				transition: all 0.2s linear;
			}
		}

		&Login {

			&[disabled] {
				cursor: no-drop;
			}

			display: flex;
			flex-grow: 1;
			justify-content: center;
			padding: 15px;
			border: 1px solid $color-theme-second;
			background: $color-theme-second;
			border-radius: $border-radius;
			color: #ffffff;
			font-family: $font-family-semibold;
			font-size: $font-size-text-large;
			line-height: $line-height-large;

			&:hover {
				background: #ffffff;
				color: $color-theme-second;

				#{ $self }__preloader {
					fill: $color-theme-second;
				}
			}
		}
	}

	&__preloader {
		width: 100%;
		fill: #ffffff;
		transition: all 0.2s linear;
	}
}

