@import "~scss/vars";

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;

	&__items {
		display: flex;
	}

	&__button {
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		margin-right: 6px;
		border: 1px solid $border-color;
		background: transparent;
		border-radius: 5px;
		color: $color-main;
		font-size: 16px;
		line-height: 130%;
		cursor: pointer;
		transition: all 100ms linear;

		&--dots {
			border: 1px solid transparent;
		}

		&:hover {
			border: 1px solid $color-theme-main;
			background: $color-theme-main;
			color: #ffffff;
		}

		&--current {
			border: 1px solid $color-theme-main;
			background: $color-theme-main;
			color: #ffffff;
		}
	}

	&__arrow {
		background: #efefef;

		&--prev {
			margin-right: 25px;
		}

		&--next {
			margin-left: 19px;
		}

		&--disable {
			cursor: no-drop;
		}

		&:hover {

			svg {
				fill: #ffffff;
			}
		}
	}

	&__icon {
		transform: rotate(90deg);
		fill: #9d9ea6;

		&--next {
			transform: rotate(-90deg);
		}

		&:hover {
			fill: #ffffff;
		}
	}
}