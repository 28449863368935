@import "~scss/vars";

.page-not-found {
	display: flex;
	padding-top: 133px;

	&__right {
		display: flex;
		flex-direction: column;
		padding-left: 15.1%;
	}

	&__title {
		margin-top: 18px;
		margin-bottom: 14px;
		font-family: $font-family-bold;
		font-size: 36px;
		line-height: $line-height-default;
	}

	&__text {
		width: 70%;
		margin-bottom: 50px;
		color: $color-second;
		font-size: $font-size-text-large;
		line-height: $line-height-large;
	}

	&__button {
		justify-content: center;
		width: 195px;
		height: 59px;
		border: 1px solid $color-theme-second;
		background: $color-theme-second;
		color: $background-color;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;
		line-height: $line-height-default;

		&:hover {
			background: $background-color;
			color: $color-theme-second;
		}
	}
}