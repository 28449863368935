@import "~scss/vars";

.order-params {
	display: flex;
	align-items: center;
	height: 78px;
	margin-bottom: 20px;
	padding-left: 20px;
	background-color: $background-color-block;
	border-radius: $border-radius;

	&--mb30 {
		margin-bottom: 30px;
	}

	&__title {
		margin-right: 50px;
		color: $color-second;
		line-height: $line-height-default;
	}

	&__radioBlock {
		display: flex;
	}

	&__radio {

		&--mr-50 {
			margin-right: 50px;
		}
	}
}