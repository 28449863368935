@import "~scss/vars";

.order {
	margin-top: 80px;

	&__title {
		margin-bottom: 30px;
	}

	&__inputComment {
		margin-bottom: 50px;

		input {
			padding: 0 20px;
		}
	}

	&__submit {
		display: flex;
		justify-content: center;
		width: 400px;
		margin: auto;
		margin-bottom: 50px;
		background: $color-theme-second;
		border-radius: $border-radius;
		color: #ffffff;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;
		line-height: $line-height-default;

		&--disabled {

			&:hover {
				background: $color-theme-second;
			}
		}
	}
}