@import "~scss/vars";

.element-detail {
	display: flex;
	flex-grow: 1;
	flex-direction: column;

	&__breadcrumbs {
		margin-bottom: 14px;
	}

	&__image {
		box-sizing: border-box;
		display: flex;
		width: 400px;
		min-width: 400px;
		height: 350px;
		overflow: hidden;
		margin-right: 50px;
		border: 1px solid #f7f7f7;
		border-radius: $border-radius;

		&Picture {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: auto;
		}

		&NoPhoto {
			display: flex;
			height: 100%;
			margin: auto;
		}
	}

	&__info {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
	}

	&__content {
		display: flex;
		flex-grow: 1;
	}

	&__article {
		margin-bottom: 40px;
		color: $color-second;
		line-height: $line-height-large;
	}

	&__price {
		display: flex;
		justify-content: space-between;
		margin-top: 40px;
		margin-bottom: 40px;
		color: $color-main;
		font-size: $font-size-text-large;
		line-height: $line-height-default;
	}

	&__priceUnit {
		display: flex;
		align-items: flex-end;
		margin-left: 15px;
		color: #75767b;
		font-size: 16px;
		line-height: 11px;
	}

	&__addToBasket {
		margin-bottom: 35px;

		&Preloader {
			width: 100%;
			height: 48px;
		}

		&Button {
			justify-content: center;
			width: 100%;
			border: 1px solid $color-theme-second;
			background: $color-theme-second;
			color: #ffffff;
			font-family: $font-family-semibold;
			font-size: $font-size-text-large;

			&:hover {
				background: #ffffff;
				color: $color-theme-second;
			}

			&--disabled {
				cursor: no-drop;
			}
		}
	}

	&__quantity {
		display: flex;
		justify-content: space-between;
		margin-bottom: 28px;
	}

	&__summ {
		line-height: $line-height-default;

		&Title {
			margin-bottom: 10px;
			color: $color-second;
			font-size: $font-size-text-large;
		}

		&Value {
			display: flex;
			color: $color-main;
			font-family: $font-family-semibold;
			font-size: $font-size-title-h1;
		}
	}

	&__wrap {
		display: flex;
	}

	&__favorite {
		fill: none;
		stroke: #969fa8;
		margin-left: 10px;
		cursor: pointer;

		&--active {
			fill: #ffdb4d;
			stroke: #ffdb4d;
		}

		&:hover {
			fill: #e5c045;
			stroke: #e5c045;
		}
	}
}
