.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%; 
	height: 100%; 
	z-index: 999;
	overflow: auto; 
	background-color: rgb(0 0 0 / 50%);
	cursor: default;

	&__title {
		margin-bottom: 40px;
		text-align: center;
	}

	&__content {
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		right: 50%;
		padding: 20px 30px 22px 30px;
		transform: translate(50%,-50%);
		background: #ffffff;
		border-radius: 4px;
	}

	&__close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 18px;
		height: 18px;
		cursor: pointer;
		fill: #999999;
	}
}