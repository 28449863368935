@import "~scss/vars";
@import "~scss/mixins/fadeInAnimation";

.price-list {
	display: flex;
	flex-direction: column;
	padding-top: 40px;

	&__title {
		margin-bottom: 25px;
		font-weight: 600;
		font-size: 28px;
		line-height: $line-height-large;
	}

	&__content {
		display: flex;
		padding-bottom: 50px;
	}

	&__checkbox {

		input {

			& + label {
				font-size: $font-size-text-veryLarge;
				line-height: $line-height-large;
				cursor: pointer;
			}
		}
	}

	&__preloader {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: 20%;
		@include fadeInAnimation();
	}

	&__error {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: 20%;
		font-size: $font-size-text-large;
	}
}