@import "~scss/vars";

.button {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding: 13px 15px;
	border: 1px solid $border-color;
	outline: none;
	background-color: #ffffff;
	border-radius: $border-radius;
	color: $color-main;
	font-family: $font-family-default;
	text-decoration: unset;
	line-height: $line-height-default;
	cursor: pointer;
	transition: all 0.2s linear;

	&--active {
		border: 1px solid $border-focus-color;
		color: $color-link;
	}

	&:hover {
		border: 1px solid $border-focus-color;
		background-color: $border-focus-color;
		color: #ffffff;
	}

	&:disabled {
		opacity: 0.5;
		cursor: no-drop;
	}
}