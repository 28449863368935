@import "~scss/vars";

.favorite-new-folder {

	&__button {
		width: 256px;
		height: 42px;
		border: 1px solid #0256b1;
		border-radius: 8px;
		color: #0058b0;
		font-size: 14px;
		font-weight: 600;
		line-height: 130%;

		&--add {
			display: flex;
			justify-content: center;
			margin: 3px auto 24px auto;
			text-align: center;
		}

		&--save {
			width: 109px;
			margin-bottom: 24px;
		}

		&--disable {
			opacity: 0.5;
			pointer-events: none;
			cursor: no-drop;
		}
	}

	&__add {
		padding: 0 32px;
		
		&--notEmpty {
			border-top: 1px solid #dde0e7;
		}

		&--proccess {
			opacity: 0.5;
			pointer-events: none;
		}

		&Title {
			margin-top: 24px;
			margin-bottom: 8px;
			color: #626c77;
			font-size: 14px;
			line-height: 140%;
		}

		&Input {
			margin-bottom: 16px;

			& > input {

				&::placeholder {
					color: #969fa8;
					font-family: $font-family-default;
					line-height: 140%;
				}
				
				padding-left: 16px;
				color: #1d2023;
				font-size: 16px;
				line-height: 140%;
			}
		}
	}
}