@import "~scss/vars";

.header-nav {
	display: flex;
	justify-content: space-between;
	padding-top: 25px;


	&__logo {
		cursor: pointer;
	}

	&__fakeHeaderMain {
		height: 50px;
	}

	&__links {
		display: flex;
		flex-wrap: nowrap;
	}

	&__link {
		color: $color-main;
		font-size: $font-size-text-middle;
		text-decoration: none;
		line-height: 23px;
		transition: all 100ms linear;

		&:not(:last-child) {
			margin-right: 51px;
		}

		&--selected,
		&:hover {
			color: $color-theme-main;
		}
	}
}