@import "~scss/vars";

.input {
	position: relative;
	height: 50px;

	&__control {
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		border: 1px solid $border-color;
		outline: none;
		border-radius: $border-radius;
		color: $color-main;
		font-family: $font-family-default;
		line-height: $line-height-default;
		transition: all 100ms linear;

		&::placeholder {
			color: #9ba0ad;
			font-family: $font-family-semibold;
			line-height: $line-height-default;
		}

		&:disabled {
			background-color: transparent;
		}

		&:focus,
		&:hover,
		&--hover {

			&:not(:disabled) {
				border: 1px solid $border-focus-color;
			}
		}
	}
}

.placeholder {
	position: absolute;
	left: 20px;
	display: flex;
	align-items: center;
	height: calc(100% + 2px);
	color: #9ba0ad;
	font-family: $font-family-semibold;
	line-height: $line-height-default;
	cursor: text;
}