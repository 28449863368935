@import "~scss/vars";

.contact {

	&__info {
		display: flex;
		flex-basis: 28%;
		flex-direction: column;
	}

	&__postcode,
	&__address {
		color: $color-second;
		font-family: $font-family-semibold;
		font-size: $font-size-title-h2;
		line-height: $line-height-large;
	}

	&__address {
		margin-bottom: 40px;
		padding-right: 24px;
		color: #1d2125;
	}

	&__schedule {
		display: inline-flex;
		flex-direction: column;
		align-self: flex-start;
		color: #1d2125;
		line-height: $line-height-default;
	}

	&__scheduleTitle {
		margin-bottom: 10px;
		color: $color-second;
		line-height: $line-height-default;
	}

	&__scheduleItem {
		display: flex;
		justify-content: space-between;
	}

	&__scheduleDays {
		display: flex;
		flex-grow: 1;
		margin-right: 10px;
	}

	&__scheduleTime {
		min-width: 79px;
		text-align: right;
	}
}
