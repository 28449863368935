@import "~scss/vars";

.header-main {
	display: flex;
	justify-content: space-between;
	padding-top: 25px;

	&__content {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 54px;
		z-index: 10;
		transition: height 0.2s linear;

		&Container {
			display: flex;
			flex-basis: 100%;
			max-width: 1400px;
			margin: 0 auto;

			&--fixed {
				box-sizing: border-box;
				max-width: 1340px;
			}
		}

		&--fixed {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			width: calc(100% - 60px);
			z-index: 5;
			margin: auto;
			padding: 25px 30px 25px 30px;
			background-color: white;
			box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
		}
	}

	&__wrap {
		display: flex;
		flex-wrap: nowrap;

		&--flexGrow1 {
			flex-grow: 1;
		}

		&--flexEnd {
			justify-content: flex-end;
		}
	}

	&__button {
		box-sizing: border-box;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 124px;
		
		height: inherit;
		margin-right: 15px;
		border: 1px solid $color-theme-main;
		background: #e3f1ff;
		border-radius: $border-radius;
		color: $color-theme-main;
		font-size: $font-size-text-large;
		font-weight: 600;
		line-height: $line-height-large ;
		cursor: pointer;
		transition: all 100ms linear;
		$self: &;

		&:hover {
			background: $color-theme-main;
			color: $background-color;
		}
	}

	&__searchInput {
		position: relative;
		width: 100%;
		height: inherit;
		
		input {
			padding: 0 163px 0 45px;
			border: 2px solid $color-theme-main;
			transition: all 300ms ease;
		}

		&Clear {
			position: absolute;
			top: 0;
			right: 19px;
			bottom: 0;
			display: flex;
			align-items: center;
			cursor: pointer;

			&Text {
				margin-right: 12px;
				color: $color-second;
			}

			&Icon {
				width: 10px;
				height: 10px;
				cursor: pointer;
				fill: $color-second;
			}
		}
	}

	&__icon {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 20px;
		margin: auto;
		cursor: pointer;

		&--search {
			fill: $color-theme-main;
		}
	}

	&__basket {
		position: fixed;
		top: 25px;
		right: 0;
		width: 330px;
		z-index: 1;
		background: #ffffff;
		border-radius: $border-radius;
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
		transition: right 250ms linear;

		&--enter {
			right: -330px;

			&Active {
				right: 0;
			}
		}

		&--exit {
			right: 0;

			&Active {
				right: -330px;
			}
		}
	}
}