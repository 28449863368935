@import "~scss/vars";

.header-basket {
	position: relative;
	display: flex;
	align-items: center;
	margin: 0 15px 0 20px;
	cursor: pointer;

	&--open {
		cursor: default;
	}

	&__icon {
		position: relative;
		display: flex;
		
		&__iconPreloader {
			width: 25px;
		}

		&--count {
			position: absolute;
			top: -11px;
			right: -11px;
			display: flex;
			justify-content: center;
			min-width: 14px;
			height: 12px;
			padding: 4px 4px 6px;
			background: #ff7800;
			border-radius: 100%;
			color: #ffffff;
			font-size: 12px;
			font-weight: 600;
			
			line-height: 14px;
			letter-spacing: -0.04em;
		}

		&--colorThemeMain {
			fill: $color-theme-main;
		}

		&--colorEmpty {
			fill: $color-table-title ;
		}
	}

	&__total {
		height: 25px;
		margin-left: 15px;
		color: $color-main;
		font-size: $font-size-text-large;
		line-height: 22px;

		&--bold {
			font-family: $font-family-semibold;
		}
	}

	&__close {
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;

		&--icon {
			width: 12px;
			height: 12px;
			fill: #000000;
			cursor: pointer;
		}
	}
}