@import "~scss/vars";

.availability-info-detail {

	&__title {
		margin-bottom: 20px;
		color: $color-main;
		font-size: $font-size-text-large;
		line-height: $line-height-default;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		color: $color-second;
		font-size: $font-size-text-large;
		line-height: $line-height-default;

		&:not(:first-child) {
			padding-top: 15px;
		}
		

		&:not(:last-child) {
			padding-bottom: 15px;
			border-bottom: 1px solid $border-color;
		}
	}
}