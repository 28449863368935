@import "~scss/vars";

.accordion {

	&__item {
		display: flex;
		flex-direction: column;
		padding-top: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid $border-color;
		cursor: pointer;

		&Header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			&Title {
				color: $color-main;
				font-size: $font-size-text-large;
				line-height: $line-height-default;
			}

			& > svg {
				fill: $color-second;
			}
		}

		&Panel {
			height: auto;
			overflow: hidden;
			transition: max-height 500ms cubic-bezier(0, 1, 0, 1);

			&--enter {
				max-height: 0;
				transition: max-height 500ms cubic-bezier(0, 0, 0, 0);

				&Active {
					max-height: 1000px;
				}
			}

			&--exit {
				max-height: 1000px;
			

				&Active {
					max-height: 0;
				}
			}

			&Content {
				padding-top: 15px;

				&Item {
					display: flex;
					justify-content: space-between;
					padding-top: 15px;
					padding-bottom: 15px;
					border-bottom: 1px solid #efefef;
					cursor: pointer;

					&Name {
						margin-right: 10px;
					}
				}
			}
		}
	}
}