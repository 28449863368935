@import "~scss/vars";

.user-counterparty-list {
	position: relative;
	display: flex;
	

	&__item {
		display: flex;
		width: 100%;
		padding: 24px 20px 20px 20px;
		border-bottom: 1px solid $border-color;

		&Info {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			margin-left: 20px;
		}

		&Icon {
			min-width: 35px;
		}

		&Name {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			color: $color-main;
			font-size: $font-size-text-default;
			line-height: 120%;
			cursor: pointer;
		}

		&Agreement {
			border-bottom: 1px solid #e0e0e0;
			color: $color-second;
			font-family: $font-family-default;
			font-size: $font-size-text-default;
			line-height: $line-height-large;
			cursor: pointer;
		}
	}

	&Other {
		position: absolute;
		top: 100%;
		left: 0;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: auto;
		z-index: 2;
		overflow: hidden;
		background: $background-color-block;
		transition: max-height 500ms cubic-bezier(0, 1, 0, 1);

		&--enter {
			max-height: 0px;
			transition: max-height 500ms ease-in-out;

			&Active {
				max-height: 1000px;
			}
		}

		&--exit {
			max-height: 1000px;

			&Active {
				max-height: 0px;
			}
		}
	}
}