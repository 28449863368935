@import "~scss/vars";

.reconciliation {
	display: flex;
	margin-top: 30px;


	&__year {
		width: 90px;
		height: 49px;
		margin-bottom: 50px;
	}

	&__reportingPeriod {
		display: flex;
		margin-bottom: 50px;
	}

	&__reportingMonths {
		display: flex;
		flex-wrap: wrap;
	}

	&__reportingYear,
	&__reportingHalfYear {
		display: flex;
		margin-right: 27px;
	}

	&__reportingQuarterYear {
		display: flex;
	}

	&__datePreset {
		width: 60.3%;
		margin-right: 50px;
		padding: 21px 26px 43px 20px;
		background: $background-color-block;
		border-radius: $border-radius;
	}

	&__button {
		margin-right: 8px;
		padding: 13px 13px;
		background-color: transparent;
		font-size: 14px;

		&--mr0 {
			margin-right: 0px;
		}

		&--mb10 {
			margin-bottom: 10px;
		}

		&--active {
			background: $color-theme-main;
			color: #ffffff;
		}
	}

	&__datePediod {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		
		padding-right: 65px;
	}

	&__datePeriodTitle {
		margin-bottom: 25px;
		color: $color-main;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;
		line-height: $line-height-large;
	}

	&__datePeriodInputCalendar {
		position: relative;
		width: 335px;
		margin-bottom: 20px;

		&--last {
			margin-bottom: 30px;
		}

		&:hover {

			& div > svg {
				right: 20px;
				fill: $color-theme-main;
			}
		}

		& input {
			padding-left: 49px;
			padding-right: 44px;
			font-size: 14px;
		}

		& div > svg {
			right: 20px;
			transition: all 100ms linear;
			fill: #9ba0ad;
		}
	}

	&__download {

		justify-content: center;

		width: 197px;
		height: 57px;
		border: 1px solid $color-theme-second;
		background: $color-theme-second;
		color: #ffffff;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;
		line-height: $line-height-default;
		transition: padding 0ms linear, background 100ms linear, color 100ms linear;

		&:hover {
			background: #ffffff;
			color: $color-theme-second;
		}

		&--loading {
			justify-content: flex-start;
			padding: 0px 42px;

			&:after {
				content: "\2026";
				display: inline-block;
				width: 0px;
				overflow: hidden;
				vertical-align: bottom;  
				animation: ellipsis steps(4,end) 900ms infinite;
			}
		}
	}

	&__actionWithDocs {
		box-sizing: border-box;
		width: 100%;
		margin-top: 20px;
	}
}