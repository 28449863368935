@import "~scss/vars";

.contact {
	padding: 156px 0px 35px 0px;

	&__item {
		display: flex;
		padding: 20px;
		background: $background-color-block;
		border-radius: $border-radius;

		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	&__empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: $color-main;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;
	}
}
