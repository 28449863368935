@import "~scss/vars";

.orders-page {

	&--invisible {
		display: none;
	}

	&__orders {
		position: relative;
		margin-top: 30px;
	}
}
