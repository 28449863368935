@import "~scss/vars";

.title {
	color: $color-main;
	font-family: $font-family-semibold;
	font-size: $font-size-title-h1;
	line-height: $line-height-large;

	&--h2 {
		font-size: $font-size-title-h2;
	}
}
