@import "~scss/vars";

.error-notification {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	max-width: 250px;
	max-height: 130px;
	z-index: 1000;
	margin: auto;
	padding: 20px 30px;
	background: $background-color;
	border-radius: $border-radius;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
	color: $color-error;

	&__message {
		margin-top: 0;
		margin-bottom: 30px;
		color: $color-error;
		font-size: $font-size-text-large;
		text-align: center;
		line-height: $line-height-large;
	}

	&__button {
		display: flex;
		justify-content: center;
		width: 100px;
		height: 32px;
		margin: auto;

		&:hover {
			background-color: $background-color-block;
			color: $color-main;
		}
	}
}
