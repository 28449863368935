@import "~scss/vars";

.product-table {

	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 14px;
		margin-bottom: 29px;
	}

	&__title {
		display: flex;
		align-items: center;
	}

	&__titleText {
		max-width: 700px;
		margin-right: 8px;
		font-size: 28px;
		font-weight: 700;
		line-height: 140%;
	}

	&__selectionDataDisplay {
		display: flex;
	}

	&__button {
		height: 34px;
		margin-right: 15px;
		color: $color-second;
		font-size: $font-size-text-default;

		&:hover {
			border-color: $border-focus-color;
			background-color: $background-color;
			color: $color-theme-main;
		}

		&--active {
			border-color: $border-focus-color;
			background-color: $background-color;
			color: $color-theme-main;
		}



		&--last {
			margin-right: 0;
		}
	}

	&__table {
		position: relative;
	}

	&__tableHeadСell {
		// position: sticky !important;
		// top: 80px; /* Don't forget this, required for the stickiness */
		// z-index: 99999;
		// background: white;
		// box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);

		&--article {
			width: 12.5%;
		}

		&--name {
			width: 28%;

			&WithoutPrice {
				width: 42%;
			}

			&LeastOnePrice {
				width: 37%;
			}
		}

		&--availability {
			width: 13%;
		}

		&--basePrice {
			width: 10%;
		}

		&--retailPrice {
			width: 10%;
		}

		&--quantity {
			
			width: 13%;
		}

		&--summ {
			width: auto;
			min-width: 107px;
		}
	}

	&__tableBodyСell {
		height: 65px;
		padding: 0;
		font-size: 14px;
		font-weight: 400;
		line-height: 140%;

		&--favorite {
			padding-left: 15px;
		}

		&--addToBasket {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-right: 15px;
		}

		&--nameWithoutPrice {
			max-width: 453px;
		}

		&--nameLeastOnePrice {
			max-width: 400px;
		}

		&--name {
			max-width: 302px;
		}
	}

	&__toElement {
		display: inline-flex;
		cursor: pointer;
		transition: all 100ms linear;

		&:hover {
			color: $color-link;
		}
	}

	&__addToBasket {
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 32px;
		background: #ffead7;
		border-radius: $border-radius;
		cursor: pointer;
		transition: all 100ms linear;
		$this: &;

		&Icon {
			transition: all 100ms linear;
			fill: transparent;
			stroke: #ff7800;
		}

		&:hover,
		&--toBasket {
			background: #ff7800;
			#{$this}Preloader {
				fill: #fafafa;
			}
			#{$this}Icon {
				stroke: #ffffff;
			}
		}

		&--disabled {
			cursor: no-drop;
		}
	}

	&__favorite {
		fill: none;
		stroke: #969fa8;
		margin-right: 15px;
		cursor: pointer;

		&--active {
			fill: #ffdb4d;
			stroke: #ffdb4d;
		}

		&:hover {
			fill: #e5c045;
			stroke: #e5c045;
		}
	}
}