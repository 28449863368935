@import "~scss/vars";

.catalog-menu {
	display: flex;
	flex-basis: 240px;
	flex-direction: column;
	min-width: 240px;
	height: 100%;
	margin-bottom: 20px;
	margin-right: 20px;
	$parent: &;

	&__lvl1 {
		$this: &;
		position: relative;
		
		background: $background-color-block;
		color: $color-main;
		font-size: $font-size-text-large;
		line-height: $line-height-large;
		cursor: pointer;
		transition: all 100ms linear;

		&:hover {
			background: #ffffff;
			color: $color-link;
		}

		&:first-child {
			border-top-left-radius: $border-radius;
			border-top-right-radius: $border-radius;
		}

		&:last-child {
			border-bottom-right-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
		}

		&__title {
			position: relative;
			padding: 15px 10px 15px 10px;

			#{$this}--open & {
				background: $background-color-block;
				color: $color-main;
			}
		}

		&--open {
			background: #ffffff;
		}

		&--active {
			background: #ffffff;
			color: $color-link;
		}
	}

	&__iconArrow {
		position: absolute;
		top: 0;
		right: 10px;
		bottom: 0;
		width: 10px;
		height: 6px;
		margin: auto;
		cursor: pointer;
		fill: #9d9ea6;

		&--open {

			transform: rotate(180deg);
		}

		&SubItem {
			right: auto;
			left: -15px;
			transform: rotate(-90deg);

			&--open {

				transform: rotate(0deg);
			}
		}
	}

	&__subLvlItems {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 15px;
		cursor: default;

		&--mt21 {
			margin-top: 21px;
		}
	}

	&__subLvlItem {
		position: relative;
		margin: 0;
		margin-bottom: 12px;
		font-size: $font-size-text-default;
		line-height: 19px;


		&--active {
			& > #{$parent}__subLvlTitle {
				color: $color-link;
			}
		}
	}

	&__subLvlTitle {
		max-width: 225px;
		margin: 0;
		color: $color-second;

		&:hover {
			color: $color-link;
		}
	}

	&__subLvlTitleText {
		$subLvlTitle: &;
		border-bottom: 1px solid #e6e6e6;
		
		cursor: pointer;

		&:hover {
			border-bottom: 1px solid $color-link;
		}
	}

	&__preloader {
		position: absolute;
		top: 0;
		bottom: 0;
		left: -15px;
		width: 12px;
		height: 12px;
		margin: auto;
	}
}

// p {
// 	margin-top: 0;
// 	margin-bottom: 30px;
// 	line-height: 1;
  
// 	&:last-child {
// 		margin-bottom: 0;
// 	}
// }