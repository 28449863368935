@import "~scss/vars";

.react-calendar {
	box-sizing: border-box;
	width: 100%;
	height: auto;
	padding: 20px 20px 25px 20px;
	border: 1px solid #e7e7e7;
	background: $background-color-block;
	border-radius: 5px;

	&__viewContainer {
		width: calc(100% + 30px);
		margin-left: -15px;
	}

	&__navigation {
		display: flex;
		width: calc(100% + 12px);
		margin-left: -9px;
		margin-bottom: 10px;

		&__label {
			flex-grow: 0 !important; /* stylelint-disable-line declaration-no-important */
			order: -1;
			padding: 0;
			border: none;
			outline: none;
			background-color: transparent;

			&__labelText {
				display: inline-flex;
				font-family: $font-family-semibold;
				font-size: 16px;
				line-height: 150%;
			}

			&__labelMonth {
				color: $color-main;
			}

			&__labelYear {
				margin-left: 5px;
				color: #b7b7b7;
			}
		}
		
		&__arrow {

			&:disabled {
				opacity: 0.5;
				cursor: default;
			}
			box-sizing: border-box;
			position: relative;
			width: 28px;
			height: 28px;
			padding: 0;
			border: 1px solid $border-focus-color;
			outline: none;
			background-color: transparent;
			border-radius: 50%;
			cursor: pointer;

			&__arrowIcon {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				width: 10px;
				height: 10px;
				margin: auto;
				fill: $color-link;

				&--prev {
					transform: rotate(90deg);
				}

				&--next {
					transform: rotate(-90deg);
				}
			}
		}

		&__prev-button {
			order: 3;
			margin-left: auto;
			margin-right: 10px;
		}

		&__next-button {
			order: 4;
		}
	}

	&__month-view {

		&__weekdays {
			margin-bottom: 8px;

			&__weekday {

				& > * {
					display: flex;
					justify-content: center;
					width: 28px;
					text-decoration: none;
				}
			}
		}
	}
	

	&__tile {
		height: 30px;
		margin-bottom: 6px;
		padding: 0;
		border: none;
		outline: none;
		background-color: transparent;
		
		&:disabled {
			opacity: 0.5;
		}

		&:not(:disabled) {

			abbr {

				&:hover {
					background-color: $color-theme-main;
					color: $background-color-block;
					cursor: pointer;
				}
			}
		}

		& > * {

			display: block;
			width: 28px;
			height: 28px;
			border: 1px solid transparent;
			background-color: transparent;
			border-radius: 50%;
			color: #0f0e0e;
			font-size: 16px;
			line-height: 30px;
		}

		&--now {

			& > * {
				border: 1px solid $color-theme-main;
			}
		}

		&--active {

			&:not([disabled]) {

				& > * {
					background-color: $color-theme-main;
					color: #fbfbfb;
				}
			}
		}
	}
}
