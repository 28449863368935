@import "~scss/vars";

.modal-action-for-uploaded-file {
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 1;
	padding: 10px 15px;
	border: 1px solid $border-color;
	background-color: $background-color-block;
	cursor: default;

	& > :first-child {
		justify-content: center;
		width: 225px;
		margin-right: 16px;
	}

	& > :last-child {
		justify-content: center;
		width: 115px;
	}
}