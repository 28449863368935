@import "~scss/vars";

.table {
	width: 100%;
	border-collapse: collapse;

	&__head {
		color: $color-table-title;
		line-height: $line-height-large;
	}

	&__body {
		color: $color-main;
		line-height: $line-height-default;
	}

	&__foot {
		color: $color-main;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;
		line-height: $line-height-large;
	}

	&__cell {
		position: relative;
		padding: 12px 0px;
		border-bottom: 1px solid $border-color;

		&:not(:last-child) {
			padding-right: 10px;
		}



		// &:after {
		// 	content: "";
		// 	position: absolute;
		// 	bottom: 0;
		// 	left: 0px;
		// 	width: 100%;
		// 	height: 1px;
		// 	background-color: $border-color;
		// }

		&--empty {
			border-bottom: 1px solid transparent;

			// &:after {
			// 	background-color: transparent;
			// }
		}

		&--head {
			padding-top: 10px;
			// padding-top: 0;
			padding-bottom: 10px;
			color: $color-main;
		}

		&--fixed {
			position: sticky;
			z-index: 1;
			top: 40px;
			background-color: #ffffff;
			box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
			font-family: $font-family-semibold;
		}
        
		&--foot {
			padding-top: 20px;
			padding-bottom: 30px;

			&:after {
				background-color: transparent;
			}
		}
	}
}