@import "~scss/vars";
@import "~scss/mixins/fadeInAnimation";

.delivery-calc {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-bottom: 30px;
	$self: &;

	&__params {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 30px;
	}

	&__select {
		flex-grow: 1;
		width: 20.5%;
		min-width: 280px;
		margin-left: 20px;

		.select {

			&__title {

				&Text {

					&--placeholder {
						color: #9ba0ad;
					}
					color: #0d1011;
				
					font-family: $font-family-default;
					font-size: 14px;
				}

				&Placeholder {
					color: #9ba0ad;
				}
			}
		}

		&:hover {

			.select {
				border: 1px solid $border-focus-color;

				svg {
					fill: $border-focus-color;
				}
			}
		}
	}

	&__input {



		&--address {
			width: 59%;
			min-width: 650px;

			&:hover {
				#{ $self }__icon {

					&--search {
						fill: $border-focus-color;
					}
				}
			}

			input {
				padding: 0 54px 0 20px;
				font-size: 14px;

				&::placeholder {
					font-family: $font-family-default;
				}
			}
		}

		&--date {
			flex-grow: 1;
			width: 20.5%;
			min-width: 255px;
			margin-left: 20px;

			&:hover {

				svg {
					fill: $border-focus-color;
				}
			}

			input {
				padding: 0 54px 0 130px;
				font-size: 14px;
			}

			svg {
				transition: all 100ms linear;
				fill: #9ba0ad;
			}
		}

		&--distance,
		&--cost {
			flex-basis: 0;
			flex-grow: 1;
			margin-right: 20px;

			input {
				padding: 0 20px 0 0;
				color: #0d1011;
				font-family: $font-family-semibold;
				font-size: 20px;
				text-align: right;
				line-height: 150%;
			}
		}

		&--cost {
			margin-right: 0px;
		}

		&--last {
			margin-right: 0px;
		}
	}

	&__inputPlaceholder {
		color: #0d1011;
	}

	&__inputPlaceholderDate {
		font-family: $font-family-default;
	}


	&__options {
		display: flex;
		width: 100%;
		margin-bottom: 30px;
	}

	&__option {
		box-sizing: border-box;
		flex-basis: 0;
		flex-grow: 1;
		border: 1px solid #efefef;
		border-radius: 5px;
		cursor: pointer;
		$self: &;

		&:not(:last-child) {
			margin-right: 20px;
		}

		&:hover {

			
			&:not(#{ $self }--disabled) {
				border: 1px solid $border-focus-color;
			}
		}

		&--active {
			border: 1px solid $border-focus-color;
			#{ $self }Top {
				background: #edf3fa;
			}
		}

		&--disabled {
			cursor: default;
			#{ $self }Name {
				color: #b4b4b4;
			}
		}

		&Top {
			box-sizing: border-box;
			display: flex;
			height: 85px;
			padding: 20px 20px 0 20px;
			background: #fbfbfb;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}

		&Bottom {
			box-sizing: border-box;
			position: relative;
			display: flex;
			align-items: center;
			height: 60px;
			padding: 15px 15px 16px 26px;
		}

		&Icon {
			margin-right: 15px;
		}

		&Name {
			color: #0d1011;
			font-size: 18px;
			line-height: 120%;
		}

		&Info {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 14px;
			height: 14.78px;
			margin-right: 20px;
			background: #efefef;
			border-radius: 50%;
			cursor: pointer;
		}

		&Price {
			color: #0d1011;
			font-family: $font-family-semibold;
			font-size: 20px;
			line-height: 150%;

			&--disabled {
				color: #b4b4b4;
				font-size: 16px;
			}
		}

		&Desc {
			box-sizing: border-box;
			position: absolute;
			top: 63px;
			left: 0;
			width: 100%;
			z-index: 1;
			border-top: 1px solid $color-theme-main  ;
			background: $background-color;
			box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.05);
			font-family: $font-family-default;
			font-size: $font-size-text-small;

			&::before {
				content: "";
				position: absolute;
				top: -14px;
				left: 11px;
				width: 42px;
				height: 42px;
				z-index: -1;
				transform: rotate(45deg);
				border: 1px solid $color-theme-main  ;
				background: $background-color;
			}

			&Wrap {
				padding: 25px 30px 30px 30px;
				background: $background-color;
			}
			
			&Text {
				font-size: $font-size-text-default;
				line-height: 160%;
			}
		}
	}

	&__icon {
		position: absolute;
		top: 0;
		right: 20px;
		bottom: 0;
		margin: auto;
		cursor: pointer;

		&--search {
			transition: all 100ms linear;
			fill: #9ba0ad;
		}

		&--calendar {
			fill: #9ba0ad;
		}
	}

	&__content {
		display: flex;
		align-self: flex-start;
		width: 100%;
		overflow: hidden;
		margin-bottom: 20px;
	}

	&__result {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__selectTransport {
		margin-right: 30px;
	}

	&__inputWrap {
		position: relative;
	}

	&__calendar {
		position: absolute;
		top: calc(100% + 5px);
		z-index: 1;
	}

	&__preloader {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		@include fadeInAnimation();
	}

	&__opacity {
		opacity: 0.3;
		pointer-events: none;
	}

	&__notDelivery {
		display: flex;
		flex: 1 1;	
		width: 100%;
	}

	&__notDeliveryText {
		display: flex;
		flex: 1 1;
		flex-direction: column;
		justify-content: center;
		width: 50%;
	}

	&__notDeliveryIcon {
		width: 50%;
	}

	&__notDeliveryTitle {
		margin-bottom: 15px;
		color: $color-main;
		font-family: $font-family-semibold;
		font-size: 36px;
		line-height: 130%;
	}

	&__notDeliveryNote {
		margin-bottom: 50px;
		color: $color-second;
		font-size: $font-size-text-large;
		line-height: $line-height-large;
	}

	&__toHome {
		display: flex;
		justify-content: center;
		width: 195px;
		height: 59px;
		background: $color-theme-second;
		color: #ffffff;
		font-size: $font-size-text-large;
		line-height: $line-height-default;
	}
}

.deliveryDescriptionTable {

	&__cell {
		padding: 20px 0 0 0;
		vertical-align: middle;
		border-bottom: none;
		color: #555555;
		font-size: 12px;
		line-height: 16px;

		&:not(:last-child) {
			padding-right: 25px;
		}
	}

	&__item {
		padding-top: 15px;
		font-size: $font-size-text-small;
		line-height: 18px;
		white-space: nowrap;

		&:not(:last-child) {
			padding-right: 25px;
		}
	}
}