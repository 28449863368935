@import "~scss/vars";

.main-page {

	&__orders {
		position: relative;
		margin-top: 30px;
		margin-bottom: 30px;
	}

	&__widgets {
		display: flex;
		margin-bottom: 30px;
	}

	&__managerInfo {
		margin-right: 30px;
	}
}
