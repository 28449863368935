@import "~scss/vars";

.modal-delete-favorite-folder {
	width: 320px;
	padding: 20px;

	

	&__title {
		margin-bottom: 20px;
		color: #0d1011;
		font-family: $font-family-bold;
		font-size: 16px;
		text-align: center;
		line-height: 140%;

		&--process {
			opacity: 0.7;
		}
	}

	&__buttons {
		display: flex;
		justify-content: space-between;

		&--process {
			opacity: 0.7;
		}
	}

	&__button {
		display: flex;
		justify-content: center;
		width: calc(50% - 15px);
	}
}