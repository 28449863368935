@import "~scss/vars";


.auth {

	&__contentBlock {
	display: flex;
	flex-basis: 430px;
	flex-direction: column;
	align-items: center;
	}

	&__wrap {
		display: flex;
		align-items: center;
		margin-top: 40px;
		margin-bottom: 54px;
		margin-left: 100px;
	}

	&__logo {
		cursor: pointer;
		margin-right: 35px;
	}

	&__phone {
		font-family: $font-family-bold;
		font-weight: 600;
		font-size: $font-size-text-large;
		line-height: $line-height-large;
		cursor: pointer;
		color: $color-theme-second ;
		text-decoration: none;
	}

	&__aboutUs {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding-top: 52px;
		padding-bottom: 52px;
		background: $color-theme-main;
		border-radius: 0px $border-radius $border-radius 0px;
	}

	&__aboutUsText {
		max-width: 231px;

		&:not(:last-child) {
			margin-bottom: 40px;
		}
	}

	&__aboutUsTitle {
		margin-bottom: 14px;
		color: #ffffff;
		font-family: $font-family-bold;
		font-size: 18px;
		line-height: 22px;
	}

	&__aboutUsParagraph {
		color: #bdddff;
		line-height: $line-height-default;
	}
}