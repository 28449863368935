@import "fonts";
@import "vars";

#root {
	display: flex;
	flex: 1 1 auto;
	flex-flow: column nowrap;
	min-height: 100vh;
}

html {
	height: 100%;
}

body {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 1280px;
	height: 100%;
	// overflow-x: hidden;
	margin: auto;
	padding: 0;
	background-color: $background-color;
	font-family: $font-family-default;
	font-size: $font-size-text-default;
}

.container {
	box-sizing: border-box;
	width: 100%;
	max-width: 1400px;
	margin: auto;
	padding: 0 30px;

	&__content {
		flex: 1 0 auto;
	}
}

.invisible {
	display: none;
}