@import "~scss/vars";

.select-transport {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	width: 330px;
	min-width: 330px;
	padding: 20px 20px 40px 20px;
	background: #fbfbfb;
	border-radius: 5px;

	&__title {
		width: 60%;
		margin-bottom: 30px;
		color: $color-second;
		font-size: 14px;
		line-height: $line-height-default;
	}

	&__controls {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 8px;
	}

	&__control {
		width: 50%;
		margin-bottom: 25px;
	}

	&__image {
		max-height: 103px;
		margin: auto;
	}

	&__params {
		display: inline-flex;
		justify-content: space-between;
		margin-top: 25px;
	}

	&__param {
		flex-basis: 0;
		flex-grow: 1;
		padding-right: 15px;

		&Value {
			color: #000000;
			font-size: 12px;
			line-height: 130%;
		}

		&Name {
			color: #9ba0ad;
			font-size: 10px;
			line-height: 130%;
		}
	}
}