@import "~scss/vars";

.switch-quantity {
	display: flex;
	$self: &;

	&__control {

		&--increase,
		&--decrease {
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 22px;
			height: 22px;
			border: 1px solid transparent;
			background: #f2f3f7;
			border-radius: 2px;
			cursor: pointer;

			&:hover {
				border: 1px solid #e2e5eb;
				background: #e2e5eb;
			}


			#{ $self }__detailPage & {
				width: 50px;
				height: 50px;
				background: #efefef;

				& > svg {
					width: 10px;
					height: 10px;
				}
			}
		}

		&--disabled {
			opacity: 0.5;
			cursor: no-drop;
		}

		&--decrease {
			#{ $self }__detailPage & {
				border-top-left-radius: 6.33333px;
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
				border-bottom-left-radius: 6.33333px;
			}
		}

		&--increase {
			#{ $self }__detailPage & {
				border-top-left-radius: 0px;
				border-top-right-radius: 6.33333px;
				border-bottom-right-radius: 6.33333px;
				border-bottom-left-radius: 0px;
			}
		}
	}





	&__input {
		position: relative;
		height: 22px;
		#{ $self }__detailPage & {
			height: 48px;
			border-top: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
		}

		input {
			max-width: 56px;
			padding: 0 10px;
			border: none;
			border-radius: 0px;
			text-align: center;
			#{ $self }__detailPage & {
				max-width: 80px;
			}

			&:focus {
				border: none;
			}
		}
	}

	&__preloader {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: auto;
		background-color: white;
	}

	&__preloaderIcon {
		position: absolute;
		right: 0;
		left: 0;
		width: 16px;
		margin: auto;
	}
}