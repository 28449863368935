@import "~scss/vars";

.header-favorite {
	position: relative;
	display: flex;
	align-items: center;
	margin-left: 32px;
	cursor: pointer;

	&__icon {
		fill: #b4b4b4;
		stroke: #b4b4b4;
		width: 36px;
		height: 36px;

		&--notEmpty {
			fill: #0058b0;
			stroke: #0058b0;
		}
	}

	&__count {
		position: absolute;
		top: 4px;
		right: -4px;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 14px;
		height: 12px;
		padding: 4px 4px 6px;
		background: #ff7800;
		border-radius: 100%;
		color: #ffffff;
		font-size: 12px;
		font-weight: 600;
		letter-spacing: -0.04em;
	}
}