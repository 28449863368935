@import "~scss/vars";
@import "~scss/mixins/fadeInAnimation";

.catalog {
	display: flex;
	flex: 1;
	margin-top: 35px;

	&__preloader {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: 20%;
		@include fadeInAnimation();
	}

	&__empty {
		display: flex;
		flex-grow: 2;
		justify-content: center;
		margin-top: 100px;
		color: #000000;
		font-family: $font-family-semibold;
		font-size: $font-size-title-h2;
		line-height: 120%;
	}
}