@import "~scss/vars";

.checkbox {
	display: inline-flex;
	$self: &;

	&__control {
		position: absolute;
		z-index: -1;
		opacity: 0;
		

		& + label {
			position: relative;
			display: inline-flex;
			align-items: center;
			padding-left: 35px;
			color: $color-main;
			user-select: none;
			cursor: pointer;
			transition: all 100ms linear;

			&:before {
				content: "";
				box-sizing: border-box;
				position: absolute;
				top: 3px;
				left: 0;
				display: inline-block;
				flex-grow: 0;
				flex-shrink: 0;
				width: 20px;
				height: 20px;
				border: 1px solid #cbd3dc;
				border-radius: 4px;
				transition: all 100ms linear;
				
				#{ $self }--notLable & {
					margin-right: 0;
				}
			}

			&:hover {
				color: $color-theme-main;
			}
		}

		&--main {

			&:checked {

				& + label {

					&:after {
						content: "";
						box-sizing: border-box;
						position: absolute;
						top: 7px;
						left: 8px;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 5px;
						height: 11px;
						margin: auto;
						transform: rotate(45deg);
						border-bottom: 2px solid $background-color;
						border-right: 2px solid $background-color;
						background-color: transparent;
					}

					&:before {
						background: $color-theme-main;
					}
				}
			}
		}

		&--second {

			&:checked {

				& + label {

					&:after {
						content: "";
						box-sizing: border-box;
						position: absolute;
						top: 7px;
						left: 8px;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 5px;
						height: 11px;
						margin: auto;
						transform: rotate(45deg);
						border-bottom: 2px solid $color-theme-main;
						border-right: 2px solid $color-theme-main;
						background-color: transparent;
					}

					&:before {
						border: 1px solid #dfecff;
						background: #dfecff;
					}
				}
			}
		}

		#{ $self }--square & {

			& + label {

				&:after {
					content: "";
					position: absolute;
					top: 8px;
					left: 5px;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 10px;
					height: 10px;
					margin: auto;
					transform: rotate(0);
					border: none;
					background-color: $color-theme-main;
				}
			}
		}
	}
}