@import "~scss/vars";

.basket {
	display: flex;
	flex-direction: column;
	margin-top: 50px;

	&__title {
		margin-bottom: 29px;
	}

	&__tableHeadСell {

		&--article {
			width: 10%;
		}

		&--name {
			width: 29%;
		}

		&--availability {
			width: 17%;
		}

		&--price {
			width: 13%;
		}

		&--quantity {
			width: 16%;
		}

		&--summ {
			width: auto;
		}
	}

	&__tableBodyСell {
		height: 75px;
		padding: 0;

		&--name {
			cursor: pointer;
			transition: all 0.2s linear;

			&:hover {
				color: $color-theme-main;
			}
		}

		&--delete {
			text-align: right;
		}

		&--skeleton {
			width: 80px;
		}

		&--skeletonTotal {
			top: 2px;
			width: 120px;
			height: 18px;
		}

		&--deleteIcon {
			width: 12px;
			height: 12px;
			cursor: pointer;
			fill: $color-second;
		}

		&--empty {
			text-align: center;
		}
	}

	&__tableFootСell {
		
		padding-top: 13px;
		padding-top: 16px;
		padding-bottom: 13px;
		padding-bottom: 9px;
		font-size: 18px;

		&:after {
			height: calc(100% - 10px);
			z-index: -1;
			background-color: $background-color-block;
		}

		&--pl15 {
			padding-left: 15px;
		}
	}
}