@import "~scss/vars";

.confirm-notification {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 400px;
	padding-top: 20px;
	padding-left: 30px;
	padding-bottom: 20px;
	padding-right: 30px;
	background: $background-color;
	border-radius: $border-radius;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
	color: $color-second;
	font-size: $font-size-text-large;
	text-align: center;
	line-height: $line-height-large;

	&__buttons {
		display: flex;
		display: flex;
		justify-content: space-between;
		margin-top: 17px;
		font-size: $font-size-text-default;

		&--cancel {
			border: 1px solid #ffc9c9;
			color: $color-error;

			&:hover {
				border: 1px solid $color-error;
				background-color: $color-error;
				color: #ffffff;
			}
		}
	}
}