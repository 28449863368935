@import "~scss/vars";

.notifications {
	// position: fixed;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 45px;
	z-index: 10;
}

.notification {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: $color-theme-main;
	line-height: 45px;
	cursor: pointer;

	&::before {
		content: "";
		position: absolute;
		width: calc(100vw - 17px);
		height: 100%;
		z-index: -1;
		background-color: $color-theme-main;
	}

	// &::after {
	// 	content: "";
	// 	position: absolute;
	// 	right: -100%;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-color: $color-theme-main;
	// }

	&__close {
		width: 16px;
		height: 16px;
		opacity: 0.35;
	}

	&__name {
		display: flex;
		margin-right: 25px;
		color: #ffffff;
		font-family: $font-family-semibold;
	}

	&__caption {
		margin-bottom: 48px;
		font-size: 20px;
		line-height: 160%;
	}

	&__button {
		align-items: center;
		margin: auto;
		background: #ececec;
		border-radius: 4px;
		color: #555555;
		font-family: $font-family-semibold;
	}

	&__modal {
		max-width: 508px;
		padding: 64px 72px;
		border-radius: 0;
	}
}

