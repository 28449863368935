@import "~scss/vars";

.price-list {

	&-sidebar {

		margin-right: 50px;

		&__unloadPrices {
			display: flex;
			align-items: center;
			margin-bottom: 45px;

			&Button {
				height: 40px;
				margin-right: 15px;
				padding: 0px 20px;
				color: #555555;
				border: 1px solid #D2D2D2;
				border-radius: 50px;
				font-size: $font-size-text-small;
				line-height: $line-height-default;
				
				&--last {
					margin-right: 0px;
				}

				&--active {
					border: 1px solid $border-focus-color;
					color: $color-theme-main;
				}
			}
		}

		&__item {
			display: flex;
			position: relative;

			&:not(:first-child) {
				padding-top: 25px;
			}

			&Name {
				color: $color-main;
				font-weight: 600;
				font-size: $font-size-text-large;
				line-height: $line-height-large;
				cursor: pointer;
				transition: all 100ms linear;

				&:hover {
					color: $color-link;
				}
			}
		}

		&__wrap {
				padding-bottom: 25px;
				border-bottom: 1px solid #EEEEEE;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

		&__redPoint {
			content: "";
			position: absolute;
			width: 4px;
			height: 4px;
			top: 3px;
			left: 135px;
			background: $color-error;
			border-radius: 50%;
		}

		&__clear {
			color: $color-second;
			cursor: pointer;
			font-size: $font-size-text-default;
			line-height: $line-height-large;
			transition: all 250ms linear;

			&:hover {
				color: $color-error;
			}
		}

		&__checkbox {

			input {

				& + label {
					font-size: $font-size-text-large;
					line-height: $line-height-large;
					cursor: pointer;
				}
			}
		}

		&__generation, {
			position: sticky;
			bottom: 15px;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 16px 30px;
			margin-top: 40px;
			outline: none;
			background: #FFF2E7;
			border-radius: 5px;
			border: none;
			color: #FF7800;
			font-weight: 600;
			font-size: $font-size-text-middle ;
			line-height: $line-height-default;
			font-family: $font-family-semibold;
			text-decoration: unset;
			transition: all 0.2s linear;
			$self: &;

			&:hover {
				background: #FF7800;
				color: $background-color;
				border: none;
				#{ $self }Preloader {
					fill:#ffffff;
				}
			}

			&Preloader {
				fill:  $color-theme-second;
			}
		}
	}
}
