@import "~scss/vars";

.availability-info {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 14px;
	height: 14.78px;
	margin-left: 18px;
	background-color: #efefef;
	border-radius: 50%;
	cursor: pointer;

	&__modal {
		box-sizing: border-box;
		position: absolute;
		top: 20px;
		left: -6px;
		display: flex;
		flex-direction: column;
		width: 250px;
		height: auto;
		z-index: 2;
		padding: 10px 15px 12px 15px;
		background-color: #ffffff;
		border-radius: 5px;
		box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	}

	&__title {
		margin-bottom: 10px;
		color: $color-main;
		font-family: $font-family-semibold;
		font-size: 12px;
		font-weight: 600;
		line-height: 130%;
	}

	&__store {
		display: flex;
		justify-content: space-between;
		color: $color-second;
		font-size: 12px;
		line-height: 130%;

		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
}