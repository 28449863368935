@import "~scss/vars";

.footer {
	box-sizing: border-box;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 38px;
	padding-right: 30px;
	border-top: 1px solid $border-color;

	&__copyright {
		color: $color-second;
		font-size: 12px;
		line-height: $line-height-large;
	}

	&__copyrightLink {
		color: $color-main;
		text-decoration: none;
		line-height: $line-height-large;
		transition: all 0.2s linear;

		&:hover {
			color: $color-link;
		}
	}
}