.user-finance-info {
	display: flex;
	flex-grow: 1;
	flex-wrap: wrap;
	background-color: #ffffff;
	transition: all 100ms linear;

	&--layer {
		background-color: #0d1011b0;
	}
	

	&__widget {
		$widget: &;

		&--row {
			flex-basis: 0;
			flex-grow: 1;
		}

		&--tile {
			width: calc(50% - 7.5px);
		}
		
		

		&--zIndexNegative {
			z-index: -1;
		}

		&--column {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding-left: 22px;
			background-color: transparent;

			&:first-child {
				margin-top: 20px;
			}

			&:last-child {
				margin-bottom: 5px;
			}
		}

		&:nth-child(odd) {

			&:not(#{ $widget }--header) {
				margin-right: 15px;
			}
		}

		&:nth-child(-n+2) {
			&:not(#{ $widget }--header) {
				margin-bottom: 15px;
			}

			&#{ $widget }--row {
				margin-bottom: 0;
				margin-right: 15px;
			}
		}
	}
}