@import "~scss/vars";

.order-modal-uploaded-file {
	box-sizing: border-box;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 250px;
	top: 53px;
	left: calc(50% - 125px);
	z-index: 1;
	padding: 25px 35px;
	background: $background-color;
	border-radius: $border-radius;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
	cursor: default;

	
	&::after {
		content: " ";
		position: absolute;
		bottom: 100%;
		border: 12px solid transparent;
		border-bottom-color: #ffffff;
	}

	&__item {
		display: flex;

		&:not(:last-child) {
			margin-bottom: 13px;
		}
	}

	&__itemTitle {
		min-width: 78px;
		margin-right: 15px;
		color: $color-main;
		font-size: $font-size-text-default;
		line-height: $line-height-default;
	}

	&__itemLink {
		color: $color-link;
		font-size: $font-size-text-small;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}