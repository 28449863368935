@import "~scss/vars";

.breadcrumbs {
	display: flex;
	flex-wrap: wrap;
	color: $color-second;
	font-size: $font-size-text-default;
	line-height: $line-height-large;

	&__item {
		display: inline-flex;
		align-items: center;
		margin-right: 10px;
		

		&Title {
			line-height: 100%;
			transition: all 0.2s linear;
			$self: &;
			
			&:not(#{ $self }--cursorDefault) {
				cursor: pointer;

				&:hover {
					color: $color-theme-main;
				}
			}

			&--cursorDefault {
				opacity: 0.7;
			}
		}
	}

	&__arrow {
		width: 10px;
		height: 6px;
		margin-left: 10px;
		transform: rotate(-90deg);
		fill: $color-second;
	}
}
