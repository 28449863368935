@import "~scss/vars";

.user-counterparty {
	box-sizing: border-box;
	display: inline-flex;
	width: 100%;
	padding: 24px 20px 20px 20px;
	border-bottom: 1px solid $border-color;

	&--other {
		cursor: pointer;
	}

	&__info {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin-left: 20px;
	}

	&__icon {
		min-width: 35px;
	}

	&__name {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		color: $color-main;
		font-size: $font-size-text-default;
		line-height: 120%;
		cursor: pointer;

		&--disabled {
			cursor: default;
		}

		&Text {
			max-width: 211px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	&__openIconArrow {
		transform: rotate(180deg);
	}

	&__agreement {
		max-width: 225px;
		overflow: hidden;
		border-bottom: 1px solid #e0e0e0;
		color: $color-second;
		font-family: $font-family-default;
		font-size: $font-size-text-default;
		text-overflow: ellipsis;
		line-height: $line-height-large;
		white-space: nowrap;
		cursor: pointer;
		transition: all 100ms linear;
		$agreement: &;

		&--disabled {
			opacity: 0.5;
			cursor: default;
		}

		&:hover {
			&:not(#{$agreement}--disabled) {
				border-bottom: 1px solid $color-link;
				color: $color-link;
			}
		}
	}
}