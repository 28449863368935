@import "~scss/vars";

.radio {
	display: flex;

	&__control {
		display: none;
	}

	&__control:checked + &__text {

		&:before {
			border: 1px solid $border-focus-color;
		}

		&:after {
			transform: scale(1);
		}
	}

	&__text {
		position: relative;
		padding-left: 22px;
		color: $color-main;
		font-size: $font-size-text-default;
		line-height: $line-height-default;
		cursor: pointer;

		&:before, &:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;  
			border-radius: 50%;
			transition: all .3s ease;
			transition-property: transform, border-color;
		}
		
		&:before {
			left: 0;
			width: 8px;
			height: 8px;
			border: 1px solid $color-input-btn;
		}

		&:after {
			left: 2px;
			width: 6px;
			height: 6px;
			transform: scale(0);
			background: $border-focus-color;
		}
	}
}