@mixin fontface($name, $weight: normal, $style: normal) {
	@font-face {
		font-family: "#{$name}";
		src: url("./../../assets/fonts/#{$name}/#{$name}.eot");
		src: url("./../../assets/fonts/#{$name}/#{$name}.eot?#iefix")format("embedded-opentype"),
			url("./../../assets/fonts/#{$name}/#{$name}.woff") format("woff"),
			url("./../../assets/fonts/#{$name}/#{$name}.ttf") format("truetype");
		font-weight: $weight;
		font-style: $style;
	}
}


