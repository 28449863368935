@import "~scss/vars";

.favorite-folders {
	border: 1px solid #dde0e7;
	border-radius: 8px;

	&__title {
		padding: 20px 24px 16px 24px;
		border-bottom: 1px solid #dde0e7;
		font-size: 16px;
		font-weight: 600;
		line-height: 140%;
	}

	&__items {

		&--notEmpty {
			padding-bottom: 16px;
		}
	}


	&__item {
		position: relative;
		display: flex;
		padding: 11px 0 11px 24px;
		font-size: 16px;
		line-height: 140%;
		cursor: pointer;

		&:hover {
			background: #e2e5eb;
		}

		&--active {
			background: #e2e5eb;
			color: #0058b0;
		}

		&--process {
			opacity: 0.5;
			pointer-events: none;
		}

		&--change {
			align-items: center;
		}

		&Name {
			display: inline-block;
			max-width: 150px;
			word-wrap: break-word;
		}

		&Count {
			height: 20px;
			margin-left: 14px;
			padding: 0 6px;
			background: #ff7800;
			border-radius: 4px;
			color: #ffffff;
			font-size: $font-size-text-default;
			font-weight: 600;
			line-height: 140%;
		}

		&Actions {
			position: absolute;
			top: 0;
			right: 24px;
			bottom: 0;
			display: flex;
			align-items: center;
		}
	}

	&__icon {

		&--first {
			margin-right: 8px;
		}

		&:hover {
			stroke: #1d2023;
		}
	}

	&__input {
		display: inline-flex;
		width: 127px;
		height: 32px;	
		border: 1px solid #0058b0;
		border-radius: 6px;

		& input {
			padding-left: 12px;
			color: #1d2023;
			font-size: $font-size-text-large;
			line-height: 140%;
		}
	}

	&__add {
		width: 247px;
		margin: 3px 24px 24px 24px;

		&--empty {
			margin-top: 24px;
		}
	}

	&__new {
		width: 247px;
		padding: 0 24px;
	}
}