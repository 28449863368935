@import "~scss/vars";

.payment-docs-list {
	padding-left: 20px;
	padding-right: 20px;
	background: $background-color-block;
	border-radius: $border-radius;

	&__topBlock {
		display: flex;
		justify-content: space-between;
		padding-top: 20px;
		padding-bottom: 30px;
	}

	&__table {

		&Cell {

			&--createdDate {
				width: 14%;
			}

			&--payDate {
				width: 16%;
			}

			&--expiredDays {
				width: 16%;
			}

			&--debetSum {
				width: 15%;
			}

			&--expDebetSum {
				width: 17%;
			}

			&--name {
				width: 22%;
			}

			&--somethingWrong {
				padding: 25px 0;
				text-align: center;
			}
		}
	}

	&__pagination {
		margin-top: 30px;
		margin-bottom: 30px;
	}
}
