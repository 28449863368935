@import "~scss/vars";

.finance-widget {
	box-sizing: border-box;
	padding: 15px 0px 20px 15px;
	background: $background-color-block;
	$self: &;

	&--header {
		padding: 0 0 25px 0;

		& > :first-child {
			display: flex;
			order: 5;
		}

		& > :last-child {
			display: flex;
			order: 4;
		}
	}

	&__icon {
		width: 50px;
		height: 50px;
		margin-bottom: 33px;
		background-position: center center;
		background-repeat: no-repeat;
		border-radius: 50%;
	}

	&__value {

		&:not(&--header) {
			margin-bottom: 5px;
		}

		&--header {
			font-size: 24px;
			line-height: 150%;
		}
		font-size: $font-size-title-h2;
		font-weight: 600;
		line-height: $line-height-large;
	}

	&__title {

		&--header {
			margin-bottom: 5px;
			color: $color-second;
			font-family: $font-family-default;
			font-size: 16px;
			line-height: 150%;
		}
		color: $color-main;
		font-size: $font-size-text-default;
		line-height: 120%;
	}




	&--prepayment {
		#{ $self }__icon {
			background-color: #DCF0CD;
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAN/SURBVHgBjVZbbtNAFD1jOxUCVcoOCCvA6kPij7CCmhU0XUGICkj9avKFVNomWUGTHbgrqPnjow+zgrorwPxBm9icO55JpmmkZiTHY8/4Ps45904UVhxhN6oHrxDCww4UmiVQVyUasw0KKX/zosR5cY84PYiz6vUKY/M4ipTCGad1+44OclSXjLpy1sz6aOqh86yD7aMoLH3cmK8SfjisPSD9aSJ0x9b3qFl4CGm0zavB8JNnHfCjLmE5RIHe5Ze4ixVG2CecBX7LfBUHTTq4qBLASBUY01Gy1DB58tbR8kvslop8lStk4GTRhouzIdU81VERPl+n8YmPvZUc6Oj6UcMr0PQUoys1zouk5lRVxvsP3mOb5coOlo1336KG3P++QJ524nzZnicODOZa67A6V8h0yvcYpkvU4w6nXgTS7JGDrX7Up1o+2WejdTyCw0P3shP37OPGSdTygfd6f0Vs6Hw/d7B9HN3IBo1lgeEkwMCmLfgHE7RKD7uib1HT1X68J2ubp9GtW9Fi1AMSqeipb1Q0i7xESuY/0nCmnZ5GbRJav9yvIhZHfoELXURAh+8HxnmDTvKHGrJFLlRIooI13IpnlvYHa1xSZyR9TusFsHe9H49m2RRVZU88vFlGrq0HTvPAX2OVVqn1FoyfzWHH2cZRhOuv8Uj2UAhDqW5GLnx1rUHue42Kh6bBKw2UkMKc2UOSWVqyQcAr0OGvFFSzqM3Xyc+IWRzCr8il8chkqyPV4igxJgejQDzSVmajN2oQcnYZZV9XrGL7ddZ1FicRLLkMLmYworR87R6J2wgDLBmCN2GQbrgjkIr0CNud5WFxGB4G7jupp8kEWVBWRdSQDjiTJVu0SbdDJwJDVzn6tutUWKafyUHtJRqEJCRUb02RhhRPHjC1c75oi85tFL5H8thzOD10YEtnaft6TbD+pfevo8+5kGw26yspFMZCciwORBWUrD7qGEmXjS1ZKtOq/0gw4L6BMXiuRCgl/kggLg+60HgkDpQ44eLkHwvtwJEryWNnHFjjtTVclFJozxxAAtvMgT6BSh4qVRvOpqwJHtyJdSRdc/KCsFR9qk7sx1ef49aiQcND06t6U1NJ23E3zTKZj/mBYocT+dZppLN59O9iToP0tN6Tdq37zZSqoRqIp/5QDhLBWQrMrYfNEza6qvllBo6U2d0Jr9ybiir/A0hvswRvn9T2AAAAAElFTkSuQmCC");
		}
		#{ $self }__value {

			&--header {
				color: $color-main;
			}
			color: #37AA00;
		}
	}

	&--limit {
		#{ $self }__icon {
			background-color: #DFECFF;
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKeSURBVHgB1VVBdtNADJVk6BYfwb1BuEFyAGj8IAm7pidoc4K6J0g5QdNdSeDZbQ9AbpBwAnwEL3kNkZBmYmNCKCkpi+q92JMZzf+jb0mD8Bj2Lo2IaaajsJoTHPFiOXgGj2HfoJA9yX8SYIgofXweRAj/w9ppSHv01YZVBNS9PRXgPggUiJTx+NUZPNAMg5ffv8CnOINeVqhMkSOg3s0FCPddOO7BDexct2XBLcjiYivwEoNwKgBZNe8XpK+TOd/xPhPv2xgRGhrmTMONtgP3GLKQI5sTwcL+k4g03cKdO20OV7GNX+rcXP0iJfl8H4nJUoGXGEZAHOuvde9Hpm460uch+Oiqzb+AAyfr4HXzBG/TJlJwrCeJAFGdl4PSWUkSfSoQFCwKMonnfwTX7AFL1RoRBt20LUDpGvEaWEWisfN7IHyhGWKyFCanAVLvVg/ICVS1QIllIkm5EfiMxwdob3NCCIYlG4/jZDWvR6LjClz8yYNOqunN5wLu405XuxLqpSeko4bT2IGUYIqDKlfNbN4yTCM7sp+efL+MUIzU3pYck9ct8/PR0unDWsWV03a0YSUyWSvtza+X5VWhbWO+0qWhlR4SLC+Xk3hUWzbd8437YCvwm6FpiiBtla6pklxQR4trm71/9dAUVjFPXKUzt1R/K8IctFv6tR0JiKBpb0RNz4/x1D6sG9ta4BJkN4LSkKH4bSy1C2ZXgn+1J0NgkoWbCdDdpVGZEa6ngMnrdWaGqXtDcOh2aEMrx+xburWHuSN4k7adj2Fpkbl7BaxfCA1XoDrhqhKsHcCqmLB7PUPfUsoPHbouOj7wLUGBKfANcx2D4EN87nrLakEvoDkDx1CrVLs81P1SPID+MHMtujS9gz2GzB0GqCrIA8P4AQD5lQRc8xfIAAAAAElFTkSuQmCC");
		}
		#{ $self }__value {

			&--header {
				color: $color-main;
			}
			color: #196EE5;
		}
	}

	&--debet {
		#{ $self }__icon {
			background-color: #E9E4FF;
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ3SURBVHgBtVbdcdpAEP7uJJ5NCeogdgWgCkzeMhkzhgqiVBBUgeUKEOMkk7eQCkTSQEgFUTpgJm8B6bK7dyAZxFgQ55vRIN3t7d/tfouCQ/QqC9Dx3kCZAQwCtIHCEkYtsS7i5FOYN4uw8tfZJTyd0WvXLpu8lQGowL3kWJdhkxFlPRflAUx5Dx+TJA1XaIFoRGc3mEDpWzHilVf7Z31KyzvymJXHyftwIgdvsj4dzI+FLRETSNmSfkbR8NsF6RjQmYi+J3VZTRt9Zyp1XnXJowwdJDgGracupRamuLf58HoHooBcaE7e5LV1ClP3JH373nN0CpdWxoIiXzhLB/L+/gLnMBpmMRm4o7v5GQ2/ohlljBbQTYvJQ0jpKccwZmkrqvYYs6D7ekkyKVrAP7bhFKT4R/h4LpTl2/9qIPkQJs9uwFaUdw3LAFRVxQ+ssaj3z1kGHG9NbQ+Z2g7VTAc5V+G2CPSJulFRCynnipJqK0P3zCB9padkJDrLQI23iFr6oXjKtELNRs8IRXkF26h37MxJBiTn286v8xY35E0m38lH4icmTYaP6NQIBvanoYuV98I5wIptRSl9faoBOy8MUfMBiE2JJJlpHWWzTHBumXZ3bz5NtQ2lxKgLmoa/JEWVzMrfWmKafnLQGFKmcOtqf85L7kxUF3Op6nKVabqQL7Ja8OB5Ah3hJlJoRrt87yEayDyZyocyMyVjr9DfxaLSE/zZzI5NMlEg9U1ULiD++Y00mdvIbWeL8oCcSJOH/rg+9D/bDbbcdInkuabBzvOCS1LpKmKWN5Lz7Z+GOTwzZlm186wa4L2docd4NNQb5FdufsTVhAP+AhDaKyTOPWvgAAAAAElFTkSuQmCC");
		}
		#{ $self }__value {

			&--header {
				color: $color-main;
			}
			color: #705dc1;
		}
	}

	&--expdebet {
				
		#{ $self }__icon {
			background-color: #FFE6E6;
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALZSURBVHgBvVZBThtBEJyeMVYOORgpIcop5gWYF0B+YCTsRVxiXmB4QcwP7BcYbhgONi/AP4Af4EOkJECUvSBhsbuTqvWOs1lsxzgoLa1m2tPT013dU2NRGfm6s1PMWdvGdFOUGoTWHrw7Pe2pCXKzu1uSIGgrkZKy9irQeuv9yckgbaOzm5asvaBzfL5VqqhFujx0UiA6DC9i57DlyL0/y+XC1AO4iU4ZzdtOZxnjIX8Xa8tPAomiEmwLyPIotlWqz733S0vFtF0urbx6ePAf8/ljOO5TR8pHiGrNijyB6FHrK6z1HkXiIJDBIQLxjTG++p8ibnIN7F7n83VMyyJCqArPdORbaweY9pF5yxU7PoDdgIJ1Y/xH4nODeoYkARUSp4MoirZWzs6uhIVl9ekcX+t+OGys9noL4UhfJooaQOATDzHD4brceV4bjmt0vtLp7KsXEPhswl89Qhdq4MY+VqFI0xl8r1bLN5VKzem329ubs/SsPSJvxCMy0bwgTCd9A3m5ROv2l0qlNNphLqjfwVGix+vOadZ+GRDTJ2HPTUoRiy0MH8Y9PerxNfZ+oreoB8b0J9pTQDGwK6pbz7PA7Fq9kPzwvPoNPqfrSUZ/wzyrpwWFrSGj5swDHObTaiDGtNM1oLBFgYYjP3VbrV5yfWoNUKA1h6nTXQ3ATeSrjTCpAcVRPIly5ATNI1IX1oAVf9PprKp/EPrhCGYVl9EDyHOhGpBaJtSgnxx0yctrwnCTjDBXDRzmrgbkrT/uBSQQ2UMKA0z5TtS4/s3z9rW7EOmXKOnrfqqvz6mP7wF1YJ3SFS8qqGbVGrMO/D/iJ98o9VnQs004ZN82gd+BWlAATZcMHAbBeZxlLtflIZI88peKVGttA1x+nH245xEEei2/6T6WiAwQL6JoirgmBjLCci5x/zpi2uc7LcJ2Ldgo2sN7cDR+0RyXA7+NbCSzxDlyOi8ba7qStP0vON6XCln7Wu4AAAAASUVORK5CYII=");
		}
		#{ $self }__value {
			color: $color-error;
		}

		#{ $self }__title {

			&--header {
				color: $color-error;
			}
		}
	}
}
