@import "~scss/vars";

.catalog-section {
	display: flex;
	flex-grow: 1;
	flex-direction: column;

	&__top {
		display: flex;
		justify-content: space-between;
	}

	&__views {
		display: flex;
	}

	&__title {
		margin-top: 14px;
		margin-bottom: 29px;
	}

	&__pagination {
		margin-top: 34px;
		margin-bottom: 30px;
	}

	&__empty,
	&__error {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 100px;
		color: $color-main;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;

		&Text {
			margin-top: 40px;
		}
	}

	&__toTop {
		position: fixed;
		right: 30px;
		bottom: 68px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		background-color: $color-theme-main;
		border-radius: 50%;
		cursor: pointer;
		transition: all 0.2s linear;

		&:hover {
			background-color: $color-table-title;
		}
	}

	&__toTopIcon {
		transform: rotate(180deg);
		fill: $background-color;
	}
}