@import "~scss/vars";

.favorite-page {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	margin-top: 20px;
	padding-top: 24px;

	&__folders {
		margin-right: 32px;
	}

	&__list {
		flex: 1 1 auto;
	}

	&__loading,
	&__empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 100px;
		color: $color-main;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;
	}

	&__table,
	&__pagination {
		margin-bottom: 40px;
	}
}
