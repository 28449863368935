@import "~scss/vars";

.order-list {
	margin-bottom: 34px;
	border-radius: $border-radius;

	&__topBlock {
		display: flex;
		justify-content: space-between;
		padding-top: 20px;
		padding-bottom: 15px;
		padding-right: 20px;
	}

	&__title {
		font-size: 28px;
		font-weight: 600;
		line-height: $line-height-large ;
	}

	&__linkToOrders {
		display: flex;
		align-items: center;
		height: 21px;
		margin-top: 4px;
		border-bottom: 1px solid $color-theme-main;
		color: $color-theme-main;
		cursor: pointer;
		transition: all 100ms linear;

		&:hover {
			border-bottom: 1px solid transparent;
		}
	}


	&__filter {
		display: flex;
		align-items: center;

		&Calendar {
			width: 250px;

			& input {
				padding-left: 49px;
				padding-right: 44px;
				font-size: $font-size-text-default;
			}

			&BetweenDelimiter {
				width: 10px;
				height: 1px;
				margin: 0px 10px;
				background-color: #9ba0ad;
			}
		}

		&Select {
			width: 172px;
			margin-left: 40px;
			background-color: #ffffff;

			&--disabled {
				background-color: transparent;
			}

			& > :first-child {
				font-family: $font-family-default;
				font-size: $font-size-text-middle;
				line-height: $line-height-default;
			}
		}
	}

	&__tableBodyRow {
		vertical-align: baseline;
		background: transparent;
		cursor: pointer;
		transition: all 100ms linear;

		&--honest {
			background: $background-color-block;
		}
		

		&:hover {
			box-shadow: inset 0 -10px 0px -7px $color-input-btn-focus ;
		}

		&--open {
		}

		&--detail {
		}
	}

	&__tableHeadСell {
		padding: 20px 0;

		color: $color-second;

		&:first-child {
			padding-left: 20px;
		}

		&:last-child {
			padding-right: 20px;
		}

		&--showDetail {
			width: 6%;
		}

		&--id {
			width: 8%;
		}

		&--number1C {
			width: 11%;
		}

		&--invoice {
			width: 11%;
		}

		&--date {
			width: 15%;
		}

		&--price {
			width: 11.9%;
		}

		&--status {
			width: 17%;
		}

		&--delivery {
			width: 11%;
		}

		&--docs {
			width: 9.5%;
		}
	}

	&__tableHeadСellDetail {
		padding: 20px 0;
		color: #75767b;
		line-height: $line-height-default ;

		&--name {
			width: 60%;
		}

		&--quantity {
			width: 9%;
		}

		&--units {
			width: 15%;
		}

		&--price {
			width: 11.3%;
		}

		&--repear {
			width: 3.1%;
		}

		&--icon {
			text-align: right;
		}
	}

	&__tableBodyСell {
		height: 30.5px;
		padding: 25px 0;
		$this: &;

		&--id {
			color: #555555;
		}

		&--invoice {

			&-item {

				&:not(:first-child) {
					padding-top: 10px;
				}
			}
		}

		&Detail {
			height: 20px;

			&--measure {
				color: #555555;
			}

			&--notBorder {
				border-bottom: none;
			}
		}

		&:first-child {
			&:not(#{$this}Detail):not(#{$this}--detailContainer) {
				padding-left: 25px;
			}
		}

		&--detail {

			&Container {
				padding-top: 0;
				padding-left: 70px;
				padding-bottom: 0;
			}
		}

		&:last-child {
			&:not(#{$this}Detail) {
				padding-right: 30px;
			}
		}

		&--pt14 {
			padding-top: 14px;
		}

		&--pb45 {
			padding-bottom: 45px;
		}

		&--number_1C {
			color: $color-second;
		}

		&--showDetail {
	
		}

		&--somethingWrong {
			padding: 25px 0;
			text-align: center;
		}
	}

	&__time {
		margin-left: 5px;
		color: $color-second;
	}



	&__uploadLink {
		position: relative;
		color: $color-link;
		cursor: pointer;
		$self: &;

		&:hover {
			&:not(#{ $self }--disabled) {
				border-bottom: 1px solid $color-link;
				color: $color-link;
			}
		}

		&--disabled {
			opacity: 0.5;
			cursor: default;
		}

		&--mr20 {
			margin-right: 20px;
		}
	}

	&__status {
		box-sizing: border-box;
		display: inline-flex;
		max-width: 214px;
		overflow: hidden;
		padding: 4px 15px;
		border-radius: 50px;
		text-overflow: ellipsis;
		text-align: center;
		white-space: nowrap;
	}

	&__showDetail,
	&__actionDetail {
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
		padding-right: 15px;
		cursor: pointer;

		&--ml20 {
			margin-left: 20px;
		}
	}

	&__preloader {
		
	}

	&__arrowIcon {
		transform: rotate(-90deg);
		transition: 0.25s all linear;
		fill: $color-second;

		&--rotate {
			transform: rotate(0deg);
			fill: $color-theme-main;
		}
	}

	&__arrowCancel {
		width: 14px;
		height: 14px;
	}

	&__comment,
	&__address {
		display: flex;
		margin-top: 20px;
		
		font-size: $font-size-text-default;
		line-height: $line-height-default;
	}

	&__commentTitle,
	&__addressTitle {
		margin-right: 20px;
		color: $color-main;
		font-family: $font-family-semibold;
	}

	&__commentText,
	&__addressText {

		color: $color-second;
	}

	&__button {
		margin: auto;
		margin-top: 15px;
		background-color: $background-color;
	}

	&__notification {
		position: absolute;
		top: 40px;
		right: 0;
		width: 220px;
		z-index: 1;
		margin: auto;
	}

	&__pagination {
		margin-bottom: 30px;
		background-color: $background-color;
	}

	&__horizontalPreloader {

		&--mr22 {
			margin-right: 22px;
		}
	}
}
