@import "~scss/vars";

.delivery {
	display: flex;
	flex-direction: column;
	margin-top: 30px;

	&__title {
		width: 100%;
		margin-bottom: 19px;
	}
}