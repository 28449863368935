@import "~scss/vars";

.change-pass-form {
	display: flex;
	flex-direction: column;
	margin-top: 120px;
	margin-left: 140px;
	$self: &;

	&__title {
		margin-bottom: 19px;
	}

	&__note {
		max-width: 400px;
		margin-bottom: 50px;
		color: rgba(15, 14, 14, 0.6);
		font-size: $font-size-text-large;
		line-height: $line-height-default;
	}

	&__input {
		max-width: 400px;
		margin-bottom: 30px;

		&--last {
			margin-bottom: 0px;
		}

		&--error {

			input {
				border: 1px solid $color-error;
			}
		}
		
		input {
			padding: 0 15px;
		}
	}

	&__bottom {
		position: relative;
		max-width: 400px;
	}

	&__submit {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: 50px;
		padding: 15px;
		border: 1px solid $color-theme-second;
		background: $color-theme-second;
		border-radius: $border-radius;
		color: #ffffff;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;
		line-height: $line-height-large;

		&:hover {
			background: #ffffff;
			color: $color-theme-second;
			#{ $self }__preloader {
				fill: $color-theme-second;
			}
		}
	}

	&__message {
		position: absolute;
		right: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		padding: 0 15px;
		font-size: $font-size-text-small;
		text-align: center;

		&--error {
			color: $color-error;
		}

		&--success {
			color: $color-success;
		}
	}

	&__preloader {
		width: 100%;
		fill: #ffffff;
		transition: all 0.2s linear;
	}
}

