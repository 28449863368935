@import "~scss/vars";

.basket-fixed {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100vh - 25px);
	max-height: 800px;

	&__top {
		box-sizing: border-box;
		margin: 0;
		padding: 18px 14px 17px 15px;
		border-bottom: 1px solid $border-color;
		background: $background-color-block;
	}

	&__item {
		margin: 0px 15px;
		padding-top: 15px;
		padding-bottom: 12px;
		border-bottom: 1px solid $border-color;
	}

	&__itemName {
		display: inline;
		font-size: 12px;
		line-height: $line-height-default;
		cursor: pointer;
		transition: all 0.2s linear;

		&:hover {
			color: $color-theme-main;
		}
	}

	&__itemPrice {

		&Skeleton {
			top: 2px;
			width: 120px;
			max-width: unset;
			height: 17px;
			margin-right: 0;
		}
	}

	&__itemParams {
		display: flex;
		margin-top: 5px;
	}

	&__switchQuantity {
		margin-right: 20px;
	}

	&__delete {
		position: relative;
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
		align-items: center;

		&--icon {
			width: 10px;
			height: 10px;
			cursor: pointer;
			fill: #9b9c9d;
		}
	}


	&__button {
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 50px;
		cursor: pointer;
		transition: all 100ms linear;

		&--clear {
			    
			border-top: 1px solid $border-color;
			color: #000000;
			font-size: $font-size-text-default;
			line-height: 120%;
		}

		&--toСheckout {
			height: 48px;
			border: 1px solid transparent;
			background: $color-theme-second;
			border-bottom-right-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
			color: #ffffff;
			font-size: $font-size-text-large;
			line-height: 120%;

			&:hover {
				border: 1px solid $color-theme-second;
				background: #ffffff;
				color: $color-theme-second;
			}
		}
	}

	&__preloader {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 10px;
		height: 10px;
		margin: auto;
	}
}

