@import "~scss/vars";

.register-form {
	display: flex;
	flex-basis: 400px;
	flex-direction: column;
	margin-top: 120px;
	margin-left: 140px;
	$self: &;

	&__title {
		margin-bottom: 19px;
	}

	&__note {
		display: flex;
		margin-bottom: 50px;
		color: rgba(15, 14, 14, 0.6);
		font-size: $font-size-text-large;
		line-height: $line-height-default;

		&Link {
			margin-left: 6px;
			color: $color-link;
			cursor: pointer;
		}
	}

	&__input {
		
		margin-bottom: 20px;

		input {
			padding: 0 15px;
		}
		
		&--error {

			input {
				border: 1px solid $color-error;
			}
		}

		&--city {

			margin-bottom: 0px;

			input {
				padding-left: 76px;
			}
		}

		&IconPassword {
			position: absolute;
			top: 0;
			right: 40px;
			bottom: 0;
			margin: auto;
			cursor: pointer;

			&:hover,
			&--active {
				fill: $color-link;
			}
		}
	}

	&__select {
		height: 50px;

		& > div {
			color: #9ba0ad;
			font-size: $font-size-text-default;
		}

		&--error {
			border: 1px solid $color-error;
		}
	}

	&__bottom {
		position: relative;
		display: flex;
		max-width: 400px;
		padding-top: 50px;
	}

	&__message {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		padding: 0 15px;
		font-size: $font-size-text-small;
		text-align: center;

		&--error {
			color: $color-error;
		}

		&--success {
			color: $color-success;
		}
	}

	&__formError {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 30px;
		color: $color-error;
	}

	&__submit {
		display: flex;
		flex-grow: 1;
		justify-content: center;
		width: 100%;
		padding: 15px;
		border: 1px solid $color-theme-second;
		background: $color-theme-second;
		border-radius: $border-radius;
		color: #ffffff;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;
		line-height: $line-height-large;

		&:hover {
			background: #ffffff;
			color: $color-theme-second;
			#{ $self }__preloader {
				fill: $color-theme-second;
			}
		}
	}

	&__preloader {
		width: 100%;
		fill: #ffffff;
		transition: all 0.2s linear;
	}
}

