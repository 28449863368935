@keyframes fadein {

	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@mixin fadeInAnimation( $speed: 2s) {
	animation: fadein $speed ease-in alternate infinite;
}