@import "~scss/vars";

.search-elements {
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	margin: auto;
	background-color: #ffffff;

	&__title {
		display: flex;
		align-items: center;
		padding-top: 35px;
		padding-bottom: 30px;

		&Text {
			margin-right: 8px;
		}
	}

	&__pagination {
		margin-top: 40px;
		margin-bottom: 10px;
	}

	&__clear {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 26px;
		height: 26px;
		background: $color-theme-main;
		border-radius: 50%;
		cursor: pointer;

		&Icon {
			width: 10px;
			height: 10px;
			cursor: pointer;
			fill: #ffffff;
		}
	}

	&__empty,
	&__loading {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 100px;
		color: $color-main;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;
	}
}