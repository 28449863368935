
@keyframes slide {

	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}

@keyframes ellipsis {

	to {
		width: 20px;
	}
}

.skeleton {
	width: 100%;
	opacity: 0.4;
	
	&--line {
		position: relative;
		z-index: 0;
		margin-right: 80px;
		background: #d0d4d4;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			width: 100%;
			height: inherit;
			z-index: 1;
			transform: translateX(100%);

			background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
			animation: slide 1s infinite;
		}
	}

	&__cell {
		position: relative;
		display: inline-block;
		width: 100%;
		max-width: 120px;
		height: 23px;
		overflow: hidden;
		border-bottom: 1px solid #efefef;
		white-space: nowrap;
	}

	&__contact {
		max-width: 330px;

		&--postcode {
			max-width: 72px;
			height: 28px;
			margin-bottom: 5px;
		}

		&--address {
			max-width: 220px;
			height: 52px;
		}

		&--schedule-title {
			max-width: 100px;
			height: 20px;
			margin-top: 42px;
		}

		&--schedule-info {
			max-width: 144px;
			height: 35px;
			margin-top: 10px;
		}
	}

	&__manager-info {
		max-width: 320px;

		&--title {
			max-width: 160px;
			height: 30px;
			margin-bottom: 105px;
		}

		&--fio {
			max-width: 190px;
			height: 50px;
			margin-bottom: 30px;
		}

		&--phone {
			max-width: 130px;
			height: 50px;
			margin-bottom: 15px;
		}

		&--email {
			max-width: 200px;
			height: 30px;
		}
	}

	&__finance-widget {

		&--header {
			width: 100%;
		}

		&--icon {
			width: 50px;
			height: 50px;
			margin-bottom: 35px;
			border-radius: 50%;
		}

		&--value {
			max-width: 115px;
			height: 25px;
			margin-bottom: 5px;
		}

		&--title {
			max-width: 210px;
			height: 22px;
		}
	}

	&__radioBtn {
		width: calc(50% - 20px);
		height: 18px;
		margin-bottom: 25px;
		margin-right: 20px;
	}

	&__loadMap {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		display: flex;
		flex-grow: 1;
		height: 350px;
		height: 350px;

		&:after {
			content: "\2026";
			display: inline-block;
			width: 0px;
			overflow: hidden;
			vertical-align: bottom;  
			animation: ellipsis steps(4,end) 900ms infinite;
		}
	}

	&__pagination {
		width: 450px;
		height: 50px;
	}

	&__catalogMenu {
		width: 100%;
		height: 100%;

		&--item {
			width: 100%;
			height: 54px;
			margin-bottom: 30px;
		}
	}

	&__orderListMain {
		height: 24px;
		margin: 30px 0;

		&--buttons {
			width: 20px;
			margin-left: 20px;
		}

		&--id {
			width: 60px;
		}

		&--number1C {
			width: 100px;
		}

		&--invoice {
			width: 120px;
		}

		&--date {
			width: 150px;
		}

		&--delivery {
			width: 100px;
		}

		&--status {
			width: 150px;
		}

		&--docs {
			width: 80px;
		}

		&--price {
			width: 100px;
		}
	}

	&__paymentDocsList {
		height: 24px;
		margin: 17px 0;

		&--createdDate {
			width: 81px;
		}

		&--payDate {
			width: 114px;
		}

		&--expiredDays {
			width: 114px;
		}

		&--debetSum {
			width: 87px;
		}

		&--expDebetSum {
			width: 122px;
		}

		&--name {
			width: 140px;
		}

		&--total {
			margin: 24px 0;
		}
	}

	&__basketList {
		height: 29px;
		margin: 22px 0;

		&--article {
			width: 57px;
		}

		&--name {
			width: 200px;
		}

		&--availability {
			width: 120px;
		}

		&--price {
			width: 100px;
		}

		&--quantity {
			width: 120px;
		}

		&--summ {
			width: 100px;
		}
	}

	&__rests {
		width: 70px;
		height: 24px;
		margin: 0;
	}
}
