@import "~scss/vars";

.price-list-popup {

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #0000003a;
	z-index: 15;

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 600px;
		height: 400px;
		border-radius: 5px;
		background: #ffffff;
		overflow: hidden;
	}

	&__preloader {
		width: 48px;
		height: 48px;
		margin-bottom: 33px;
	}

	&__iconSuccess {
		margin-bottom: 33px;
	}

	&__title {
		margin-bottom: 15px;
		font-weight: 600;
		font-size: $font-size-title-h1;
		line-height: $line-height-default;
	}

	&__info {
		padding: 0 120px;
		text-align: center;
		font-size: $font-size-text-default;
		line-height: 160%;
		color: #323232;
	}

	&__download {
		margin-top: 40px;
		padding: 16px 30px;
		border-radius: 4px;
		font-weight: 600;
		font-size: $font-size-text-default;
		line-height: $line-height-default;
		color: #FF7800;
		background: #FFF2E7;
		transition: all 250ms linear;
		text-decoration: none;
		cursor: pointer;

		&:hover {
			color: #ffffff;
			background: #FF7800;
		}
	}
}