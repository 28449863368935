@import "~scss/vars";

.manager-info {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	width: 320px;
	padding: 20px 20px 30px 20px;
	outline: none;
	background: $background-color-block;

	&__title {
		margin-bottom: 106px;
	}

	&__fio {
		margin-bottom: 30px;
		color: $color-main;
		font-family: $font-family-semibold;
		font-size: $font-size-text-large;
		line-height: $line-height-large;
	}

	&__phone {
		color: #000000;
		font-size: $font-size-text-large;
		line-height: $line-height-large;
	}

	&--mb-4 {
		margin-bottom: 4px;
	}

	&--mb-15 {
		margin-bottom: 15px;
	}

	&__email {

		&Link {
			color: #000000;
			font-size: $font-size-text-large;
			text-decoration: none;
			line-height: $line-height-large;
			transition: all 0.2s linear;

			&:hover {
				color: $color-theme-main;
			}
		}
	}
}