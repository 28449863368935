@import "~scss/vars";

.сheckoutSuccess {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 420px;
	margin: auto;
	margin-top: 125px;

	&__title {
		margin-bottom: 20px;
		font-family: $font-family-bold;
		font-size: 36px;
		text-align: center;
	}

	&__text {
		display: flex;
		margin-bottom: 50px;
		color: $color-second;
		font-size: $font-size-text-large;
		text-align: center;
		line-height: 24px;
	}

	&__orderId {
		color: $color-main;
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__button {
		justify-content: center;
		width: calc(50% - 20px);
		font-size: $font-size-text-large;
		line-height: 21px;

		&--toHome {
			border: 1px solid $color-theme-second;
			background-color: $color-theme-second;
			color: #ffffff;

			&:hover {
				background-color: #ffffff;
				color: $color-theme-second;
			}
		}

		&--toCatalog {
			border: 1px solid $color-theme-second;
			background-color: #ffffff;
			color: $color-theme-second;

			&:hover {
				background-color: $color-theme-second;
				color: #ffffff;
			}
		}
	}
} 
