@import "~scss/vars";

.price-list-tree {
	display: flex;
	flex: 1;
	justify-content: space-between;
	margin-top: 85px;

	&__column {
		display: flex;
		flex-basis: 50%;
		flex-direction: column;
		padding: 0 8px;
	}

	&__item {
		$self: &;

		&__section {
			display: flex;
			margin-bottom: 20px;

			&Title {
				color: $color-second;
				font-size: $font-size-text-large;
				line-height: $line-height-large;

				&--bold {
					color: $color-main;
					font-family: $font-family-semibold;
					font-size: $font-size-text-large;
					line-height: $line-height-large;
				}
			}
		}

		&--lvl2 {
			margin-bottom: 40px;
		}
	}

	&__notSelected {
		display: flex;
		flex: 1;
		justify-content: center;
		border: 1px dashed #D2D2D2;
		border-radius: 4px;
	}

	&__wrap {
		margin-top: 220px;
		text-align: center;
	}

	&__title {
		padding-bottom: 15px;
		font-weight: 600;
		font-size: $font-size-title-h1;
		line-height: $line-height-default;
	}

	&__info {
		padding: 0 300px;
		font-size: $font-size-text-default;
		line-height: 160%;
		color: #323232;
	}
}