@import "~scss/vars";

.notification-settings {
	padding: 60px 80px;

	&__title {
		margin-bottom: 32px;
		font-family: $font-family-semibold;
		font-size: 32px;
		line-height: 150%;
	}

	&__items {
		display: flex;
		flex-direction: column;
		margin-bottom: 48px;
	}

	&__item {
		height: 26px;
		font-family: $font-family-default;

		&:first-child {
			margin-bottom: 24px;
		}
	}

	&__buttons {
		position: relative;
		display: flex;
		justify-content: center;
	}

	&__button {
		border-radius: 0px;
		font-family: $font-family-semibold;
		font-size: 14px;
		line-height: 150%;

		&--save {
			margin-right: 24px;
			padding: 16px 32px;
			border: 1px solid transparent;
			background: #ff7800;
			color: #ffffff;
		}

		&--cancel {
			padding: 16px 32px;
			border: 1px solid transparent;
			background: #ececec;
			color: #555555;

			&:hover {
				background: $color-main;
				color: #ffffff;
			}
		}
	}

	&__message {
		position: absolute;
		top: -25px;
		color: $color-success;
		font-size: 12px;
	}
}
