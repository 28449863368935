@import "~scss/vars";

.header-user {
	position: relative;
	display: flex;
	align-items: center;
	color: $color-main;
	font-family: $font-family-semibold;
	font-size: $font-size-text-large;
	line-height: $line-height-small;
	cursor: default;

	&__current {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	&__name {
		max-width: 130px;
		overflow: hidden;
		margin-right: 25px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__arrow {

		&--open {
			transform: rotate(180deg);
		}
	}

	&__info {
		$infoHeight: 513px;
		position: absolute;
		top: 55px;
		right: 0;
		display: flex;
		flex-direction: column;
		width: 320px;
		z-index: 1;
		overflow: hidden;
		background: #ffffff;
		border-radius: 5px;
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
		transition: max-height 500ms ease;
	
		&--enter {
			max-height: 0;

			&Active {
				max-height: 513px;
			}
		}

		&--exit {
			max-height: 513px;

			&Active {
				max-height: 0;
			}
		}
	}

	&__listAgreement {

		&--layerShadow {
			background: #0d1011b0;
		}

		&--empty {
			padding: 24px 20px 20px 20px;
		}
		display: flex;
		flex-grow: 2;
		flex-direction: column;
		justify-content: space-between;
		height: 428px;
		padding-top: 20px;
	}

	&__logout {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 48px;
		z-index: -1;
		background: $background-color-block;
		border-bottom-right-radius: $border-radius;
		border-bottom-left-radius: $border-radius;
		color: $color-main;
		cursor: pointer;
		transition: all 100ms linear;

		&--layerShadow {
			background: #0d1011b0;
		}

		&:hover {
			background: $color-main;
			color: $background-color-block;
		}

		&Text {
			margin-right: 10px;
		}
	}

	&__login {
		display: flex;
		justify-content: center;
		align-items: center;
		width: calc(100% - 2px);
		height: 48px;
		border: 1px solid transparent;
		background: $color-theme-second;
		border-bottom-right-radius: $border-radius;
		border-bottom-left-radius: $border-radius;
		color: #ffffff;
		font-family: $font-family-default;
		font-size: $font-size-text-default;
		line-height: 19px;
		cursor: pointer;
		transition: all 100ms linear;

		&:hover {
			border: 1px solid $color-theme-second;
			background: #ffffff;
			color: $color-theme-second;
		}

		&--layerShadow {
			z-index: -1;
		}
	}

	&__notification {
		display: flex;
		justify-content: center;
		width: 279px;
		margin: auto;
		margin-top: 20px;
		border: 1px solid transparent;
		background: rgba(255, 120, 0, 0.15);
		color: #ff7800;
		font-family: $font-family-semibold;
		font-size: 14px;
		text-align: center;
		line-height: 150%;
	}
}