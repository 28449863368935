$font-family-light          : "OpenSansLight";
$font-family-default        : "OpenSansRegular";
$font-family-semibold       : "OpenSansSemibold";
$font-family-bold    		: "OpenSansBold";

// colors
$color-theme-main           : #0256b1;
$color-theme-second         : #ec6608;

$color-main            		: #0d1011;
$color-second          		: #75767b;
$color-success         		: #348d50;
$color-error           		: #eb5757;
$color-link            		: #0256b1;

$color-table-title          : #b4b4b4;

$background-color           : #ffffff;
$background-color-block     : #f5f5f5;

$border-color		   		: #efefef;
$border-focus-color			: #0256b1;

$color-input-btn            : #c4c8cc;
$color-input-btn-focus      : #0256b1;
 
// font size
$font-size-title-h1         : 24px;
$font-size-title-h2         : 20px;
$font-size-text-veryLarge	: 18px;
$font-size-text-large       : 16px;
$font-size-text-middle      : 15px;
$font-size-text-default     : 14px;
$font-size-text-small	    : 13px;

// other style
$border-radius              : 5px;
$line-height-small	        : 110%;
$line-height-default        : 130%;
$line-height-large          : 150%;





